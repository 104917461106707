/*--
    15. Brand Css
------------------------*/
.brand-bg{
    background: url(../images/bg/brand-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
.brand-group {
    & .brand-item {
        padding: 10px 0;
        text-align: center;
        border-bottom: 1px solid #ebebeb;
        
        & img {
            transform: scale(1.0);
        }
        &:hover {
            & img {
                 transform: scale(1.1);
            }
        }
        &:last-child {
            border: none;
        }
    }
}
.our-brand-active {
    & .brand-group {
        border-right: 1px solid #ddd;
        @media #{$small-mobile}{
            border-right: none;
        }
        &.last-elem{
            border-right: none;
        }
    }
    
}
.brand-box {
    padding: 30px;
    background: $white;
    border: 1px solid #ddd;
    border-radius: 3px;
}
.brand-active-two{
    
}