/*--
    11. Footer Css
-------------------------*/
.footer-top{
    background: #2f2f2f;
}
.widget-footer {
    & .title-widget {
        margin-bottom: 30px;
        color: $white;
        text-transform: uppercase;
    }
    & p {
        color: $white;
    }
}

.contact-call-wrap,.contact-call-wrap-top {
    display: flex;
    align-items: center;
    & .footer-call {
        margin-left: 15px;
        & p {
            color: #ffffff;
            font-size: 1em;
            line-height: 1;
            font-weight: 400;
            margin-bottom: 10px;
        }
        & h6 {
            color: #80bb01;
            font-size: 1.2857em;
            line-height: 1;
        }
    }
}
.contact-call-wrap-top {
    & .footer-call {
        margin-left: 15px;
        & p {
            color: #333;
        }
        
    }
}
.footer-addres {
    margin-top: 10px;
    & p {
        color: #fff;
        margin-bottom: 0;
    }
}

.social-icons {
    margin-top: 20px;
    & li {
        display: inline-block;
        margin: 0 10px 5px 0;
        position: relative;
        vertical-align: middle;
        & a {
            color: #fff;
            display: block;
            font-size: 16px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            width: 40px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
        }
    }
}
.newletter-wrap{
    & .newsletter-title {
        & h3 {
            color: $white;
            margin-bottom: 0;
            & span {
                color: $theme-color;
            }
            @media #{$large-mobile}{
                line-height: 24px;
            }
        }
        @media #{$large-mobile}{
            &.mb-30 {
                 margin-bottom: 15px;
            }
        }
    }
    & p {
        color: $white;
        margin-bottom: 0;
    }
}

.newletter-area{
    background: #2f2f2f;
}
.newletter-wrap {
    border-top: 1px solid #ccc;
    padding-top: 30px;
}

.newsletter-footer {
    position: relative;
    & input {
        background: #fff;
        border: none;
        color: #888888;
        display: block;
        font-size: 0.929em;
        font-style: italic;
        height: 45px;
        line-height: 45px;
        width: 100%;
        padding: 0 140px 0 20px;
        text-align: left;
        white-space: nowrap;
        border-radius: 3px;
    }
    & .subscribe-button {
        width: 100%;
    }
    & .subscribe-btn {
        position: absolute;
        top: 0;
        right: 0;
        background: $theme-color;
        border: none;
        color: #fff;
        cursor: pointer;
        font-size: 0.8571em;
        height: 45px;
        line-height: 45px;
        margin: 0;
        font-weight: 500;
        padding: 0 30px;
        text-align: center;
        -webkit-transition: 0.3s ease;
        -moz-transition: 0.3s ease;
        -ms-transition: 0.3s ease;
        -o-transition: 0.3s ease;
        transition: 0.3s ease;
        -webkit-border-radius: 0 3px 3px 0;
        -moz-border-radius: 0 3px 3px 0;
        border-radius: 0 3px 3px 0;
        &:hover {
            background: #5D8801;
        }
    }
}

.footer-list {
    & li {
        margin-bottom: 8px;
        color: #fff;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.footer-bottom {
    background: $theme-color-1;
    padding: 40px 0;
    color: #fff;
    & .copy-right-image {
        text-align: right;
    }
    @media #{$large-mobile}{
        & .copy-left-text {
            margin-bottom: 20px;
        }
        text-align:  center;
        & .copy-right-image {
            text-align: center;
        } 
    }
}
