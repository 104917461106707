/*--
    13. Widget Css
---------------------*/

.shop-box-area,.blog-sidebar-widget-area{
    border: 1px solid #ebebeb;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 20px;
    background: #f9f9f9;
}
.shop-sidebar {
    & h4 {
        font-size: 16px;
        font-weight: 500;
        color: $black;
        margin-bottom: 25px;
        padding-bottom: 15px;
        position: relative;
        &::before {
            position: absolute;
            content: "";
            background: #ebebeb;
            width: 100%;
            height: 1px;
            left: 0;
            bottom: -1px;
        }
    }
}
.widget-title {
        font-size: 16px;
        font-weight: 500;
        color: $black;
        margin-bottom: 25px;
        padding-bottom: 15px;
        position: relative;
        &::before {
            position: absolute;
            content: "";
            background: #ebebeb;
            width: 100%;
            height: 1px;
            left: 0;
            bottom: -1px;
        }
    }
.category-sub-menu {
    & ul {
        & li {
            &.has-sub {
                & > a {
                    color: #363f4d;
                    cursor: pointer;
                    display: block;
                    font-size: 14px;
                    line-height: 14px;
                    padding: 10px 0 10px 15px;
                    position: relative;
                    &:after {
                        color: #333333;
                        content: "\e930";
                        font-family: plaza-icon;
                        font-size: 14px;
                        position: absolute;
                        right: 5px;
                    }
                    &::before {
                        color: #333333;
                        content: "+";
                        font-family: plaza-icon;
                        position: absolute;
                        left: 0px;
                    }
                    &:hover {
                        color: $theme-color;
                    }
                }
                &.open {
                    display: block;
                    width: auto;
                    & > a {
                        &:after {
                          transform: rotate(-90deg);
                        }
                    }
                }
                & li {
                    
                    & a {
                        border: 0 none;
                        border-bottom-color: currentcolor;
                        border-bottom-style: none;
                        border-bottom-width: 0px;
                        display: block;
                        font-size: 14px;
                        line-height: 14pxpx;
                        margin: 0 0px 10px 0;
                        padding: 0 28px 5px;
                        position: relative;
                        color: #333;
                        &:hover {
                            color: $theme-color;
                        }
                        &::before {
                            color: #333333;
                            content: "+";
                            font-family: plaza-icon;
                            position: absolute;
                            left: 10px;
                        }
                    }
                    &:last-child {
                        & a {
                            border: none;
                            margin-bottom: 10px;
                        }  
                    }
                }
            }
            & ul {
                display: none;
                margin-top: 10px;
            }
        }
    }
}

.ui-widget-content {
    background: #DBDBDB none repeat scroll 0 0;
    border: medium none;
    border-radius: 5px;
    height: 4px;
    margin: 35px 0 25px;
    & .ui-state-default {
        background: $theme-color;
        border: 2px solid $white;;
        border-radius: 25px;
        box-shadow: none;
        color: #ffffff;
        cursor: pointer;
        font-weight: normal;
        height: 15px;
        width: 15px;
        &:hover {
            background: $theme-color;
        }
    }
    & .ui-slider-range {
        background: $theme-color;
    }
}
.filter-price-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
          flex-direction: row;
  font-size: 13px;
  margin-bottom: 10px;
}
.filter-price-wapper {
    & .add-to-cart-button {
        background: #333;
        display: inline-block;
        font-weight: 500;
        padding: 4px 13px;
        right: 0;
        color: $white;
        border-radius: 5px;
        margin-bottom: 10px;
        &:hover {
          background: $black;  
        }
    }
}
.filter-price-cont { 
    font-size: 14px;
    & .input-type {
        & input {
            border: medium none;
            padding: 0 8px 0 0;
            text-align: center;
            width: 40px; 
            background: transparent;
            font-weight: 500;
        }
        &:last-child {
            padding-left: 8px;
        }
    } 
}

.category-widget-list {
    & li {
        & a {
            margin-bottom: 10px;
            position: relative;
            padding: 0 0 0 15px;
            line-height: auto;
            &::before {
                color: #333333;
                content: "+";
                font-family: plaza-icon;
                position: absolute;
                left: 0px;
                top: 45%;
                transform: translateY(-50%);
            }
        }
    }
}

.sidebar-tag {
    & li {
        display: inline-block;
        & > a {
            border: 1px solid #ddd;
            color: #333;
            font-size: 13px;
            margin: 10px 10px 0  0;
            padding: 4px 15px;
            border-radius: 5px;
            text-transform: capitalize;
            &:hover {
                background: $theme-color;
                color: $white;
                border: 1px solid $theme-color;
            }
        }
        
    }
}
.shop-top-bar {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    align-items: center;
    border-radius: 3px;
}
.shop-item-filter-list {
    & li {
        & a {
            &::before {
                content: '';
                display: block;
                height: 20px;
                width: 25px;
                -webkit-transition: 0.3s ease;
                -moz-transition: 0.3s ease;
                -ms-transition: 0.3s ease;
                -o-transition: 0.3s ease;
                transition: 0.3s ease;
            }
            &.grid-view{
                &::before {
                    background: url(../images/icon/gridview.png) no-repeat center center;
                }
                &.active {
                    &::before {
                        background: url(../images/icon/gridview-hover.png) no-repeat center center;
                    }
                }
            }
            
            &.list-view::before {
                background: url(../images/icon/listview.png) no-repeat center center;
            }
            &.active {
                &::before {
                    background: url(../images/icon/listview-hover.png) no-repeat center center;
                }
            }
        }
        & .active {
            color: $theme-color;
        }
    }
}
.shop-banner {
    & img {
        width: 100%;
    }
}
.product-short {
    display: flex;
    font-size: 14px;
    align-items: center;
    & p {
        margin: 0 15px 0 0;
        @media #{$small-mobile}{
            display: none;
        }
    }
    & .nice-select {
        border: 1px solid #ddd;
        padding: 0px 15px;
        height: 32px;
        width: 170px;
        line-height: 32px;
        border-radius: 3px;
    }
}
.shop-sidebar-wrap {
    @media #{$tablet-device}{
        margin-top: 80px;
    }
    @media #{$large-mobile}{
        margin-top: 70px;
    }
    @media #{$small-mobile}{
        margin-top: 60px;
    }
}

.search-widget {
    & .form-input {
        position: relative;
        & input {
            background: #fff;
            height: 40px;
            line-height: 40px;
            outline: none;
            padding: 0 50px 0 15px;
            width: 100%;
            border-radius: 3px;
            border: 1px solid #ebebeb ;
        }
        & .button-search {
            background: transparent;
            border: none;
            color: #777;
            cursor: pointer;
            font-size: 18px;
            height: 40px;
            line-height: 40px;
            margin: 0;
            outline: none;
            padding: 0;
            position: absolute;
            right: 0;
            text-align: center;
            top: 0;
            width: 40px;
            &:hover {
                color: $theme-color;
            }
        }
    }
}

.single-widget-post {
    overflow: hidden;
    margin-bottom: 20px;
    & .post-thumb {
        float: left;
        max-width: 30%;
    }
    & .post-info {
        float: left;
        padding-left: 20px;
        max-width: 70%;
        & .post-date {
            font-size: 13px;
        }
    }
}
