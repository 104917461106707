/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/

/*-- Google Font --*/
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900');

/*-- Common Style --*/
*, *::after, *::before {
  box-sizing: border-box;
}
html, body {
    height: 100%;
}
body{
    background-color: $white;
    line-height: 23px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    visibility: visible;
    font-family: $body-font;
    color: $body-color;
    position: relative;
}
h1, h2, h3, h4, h5, h6 {
    color: $heading-color;
    font-family: $heading-font;
    margin-top: 0;
}
h1 {
    font-size: 48px;
    line-height: 55px;
}
h2 {
    font-size: 36px;
    line-height: 36px;
    
}
h3 {
    font-size: 24px;
    line-height: 30px;
}
h4 {
    font-size: 20px;
    line-height: 28px;
}
h5 {
    font-size: 16px;
    line-height: 20px;
}
h6 {
    font-size: 14px;
    line-height: 18px;
    
}

@media #{$large-mobile}{
    h1 {
        font-size: 34px;
        line-height: 42px;
        & br {
            display: none;
        }
    }
}
@media #{$small-mobile}{
    h1 {
        font-size: 26px;
        line-height: 32px;
        & br {
            display: none;
        }
    }
}
p {
    font-size: 14px;
}
p:last-child {
    margin-bottom: 0;
}
a, button {
    color: inherit;
    display: inline-block;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
}
a:hover {
    color: $theme-color;
}
a, button, img, input, span {
    transition: all 0.3s ease 0s;
}
*:focus {
    outline: none !important;
}
a:focus {
    color: inherit;
    outline: none;
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
button, input[type="submit"] {
    cursor: pointer;
}
ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
img {
    max-width: 100%;
}
button:focus,
a:focus{
    outline: none;
    box-shadow: none;
}
.btn, .btn:focus {
    outline: 0;
    box-shadow: none !important;

}
.header-sticky {
    &.is-sticky {
        position: fixed;
        width: 100%;
        z-index: 999999999;
        top: 0;
    }
    &.bg-white {
        &.is-sticky {
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
            position: fixed;
            width: 100%;
            z-index: 999999999;
            top: 0;
        }
    }
}
/*-- 
    - Common Classes
-----------------------------------------*/
.fix {
    overflow:hidden
}
.hidden {
    display: none;
}
.clear{
    clear: both;
}
.section {
    float: left;
    width: 100%;
}
@media #{$large-mobile}{
    .container {
        width: 450px;
    }
}
@media #{$small-mobile}{
    .container {
        width: 300px;
    }
}

.bold {
    font-weight: 700;
}
.semi-bold {
    font-weight: 600;
}
.medium {
    font-weight: 500;
}
.light {
    font-weight: 300;
}

.capitalize {
    text-transform: capitalize;
}
.uppercase {
    text-transform: uppercase;
}

.no-gutters {
    margin-left: 0;
    margin-right: 0;
    & > .col, & > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
        margin: 0;
    }
}
.row-8 {
    margin-right: -8px;
    margin-left: -8px;
}

/*-- Container Fluid --*/
.container-ext {
    max-width: 1400px;
    margin: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
    
    // Responsive
    @media #{$laptop-device}{
        max-width: 1400px;
    }
    @media #{$desktop-device}{
        max-width: 1400px;
    }
    @media #{$tablet-device}{
        max-width: 1400px;
    }
    @media #{$large-mobile}{
        max-width: 1400px;
    }
    @media #{$small-mobile}{
        max-width: 1400px;
    }
}



/*--
    - Paginatoin Css
---------------------------------*/
.pagination-box {
    display: flex;
    margin-top: 40px;
    border:  1px solid #ddd;
    border-radius: 3px;
    padding: 10px;
    @media #{$large-mobile} {
        margin-top: 40px;
    }
    
    & li {
        display: inline-block;
        margin: 0 4px;
        &.active a {
            color: $white;
            background: $theme-color;
        }
        & a {
            color: #333333;
            display: block;
            padding: 0 12px;
            height: 30px;
            background: #f1f1f1;
            line-height: 30px;
            text-align: center;
            font-size: 14px;
            border-radius: 3px;
            &:hover {
                color: $white;
                background: $theme-color;
            }
            & span {
                font-size: 18px;
            }
        }
    }
}
.border-bm-1 {
    border-bottom: 1px solid rgba(255,255,255,0.1);
}
.border-tp-1 {
    border-top: 1px solid rgba(255,255,255,0.1);
}

.mt-10 {
    margin-top: 10px;
}
.mt-15{
    margin-top: 15px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-40 {
    margin-top: 40px;
}
.mt-50 {
    margin-top: 50px;
}
.mt-60 {
    margin-top: 60px;
}
.mt-70 {
    margin-top: 70px;
}
.mt-80 {
    margin-top: 80px;
}

.mb-10 {
    margin-bottom: 10px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-50 {
    margin-bottom: 50px;
}
.mb-60 {
    margin-bottom: 60px;
}
.mb-70 {
    margin-bottom: 70px;
}
.mb-80 {
    margin-bottom: 80px;
}

@media #{$desktop-device}{
    .d-mt-20 {
        margin-top: 20px;
    }
    .d-mt-30 {
        margin-top: 30px;
    }
}

/*-- 
    - Section Padding
-------------------------------------*/
// Padding Top Bottom 
.section-ptb {
    padding: 60px 0;
    @media #{$desktop-device} {
        padding: 60px 0;
    }
    @media #{$tablet-device} {
        padding: 60px 0;
    }
    @media #{$large-mobile} {
        padding: 60px 0;
    }
    @media #{$small-mobile} {
        padding: 60px 0;
    }
}
// Section Padding Top
.section-pt {
    padding-top: 60px;
    
    @media #{$desktop-device} {
        padding-top: 60px;
    }
    @media #{$tablet-device} {
        padding-top: 60px;
    }
    @media #{$large-mobile} {
        padding-top: 60px;
    }
    @media #{$small-mobile} {
        padding-top: 60px;
    }
}
// Section Padding Top
.section-pt-100 {
    padding-top: 100px;
    
    @media #{$desktop-device} {
        padding-top: 100px;
    }
    @media #{$tablet-device} {
        padding-top: 60px;
    }
    @media #{$large-mobile} {
        padding-top: 60px;
    }
    @media #{$small-mobile} {
        padding-top: 60px;
    }
}
.section-pt-60 {
    padding-top: 60px;
    
    @media #{$desktop-device} {
        padding-top: 60px;
    }
    @media #{$tablet-device} {
        padding-top: 20px;
    }
    @media #{$large-mobile} {
        padding-top: 20px;
    }
    @media #{$small-mobile} {
        padding-top: 20px;
    }
}
.section-pt-30 {
    padding-top: 30px;
}

// Section Padding Bottom
.section-pb {
    padding-bottom: 60px;
    
    @media #{$desktop-device} {
        padding-bottom: 60px;
    }
    @media #{$tablet-device} {
        padding-bottom: 60px;
    }
    @media #{$large-mobile} {
        padding-bottom: 60px;
    }
    @media #{$small-mobile} {
        padding-bottom: 60px;
    }
}
.section-pb-100 {
    padding-bottom: 100px;
    
    @media #{$desktop-device} {
        padding-bottom: 100px;
    }
    @media #{$tablet-device} {
        padding-bottom: 60px;
    }
    @media #{$large-mobile} {
        padding-bottom: 60px;
    }
    @media #{$small-mobile} {
        padding-bottom: 60px;
    }
}
// Section Padding Bottom
.section-pb-40 {
    padding-bottom: 40px;
    
    @media #{$desktop-device} {
        padding-bottom: 40px;
    }
    @media #{$tablet-device} {
        padding-bottom: 0px;
    }
    @media #{$large-mobile} {
        padding-bottom: 0px;
    }
    @media #{$small-mobile} {
        padding-bottom: 0px;
    }
}
.section-pb-30 {
    padding-bottom: 30px;
}

.bg-image {
    background-size: cover;
}


/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder, textarea:-moz-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
input::-moz-placeholder, textarea::-moz-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

/*-- 
    Scroll Up 
-----------------------------------------*/
#scrollUp {
    width: 40px;
    height: 40px;
    background-color: $theme-color;
    border: 3px solid #eeeeee;
    color: $white;
    right: 20px;
    bottom: 60px;
    text-align: center;
    overflow: hidden;
    border-radius: 50px;
    z-index: 9811 !important;
    @media #{$large-mobile}{
        display: none !important;
    }
    & i {
        display: block;
        line-height: 34px;
        font-size: 16px;
    }
    &:hover {
        & i {
            animation-name: fadeInUp;
            animation-duration:1s;
            animation-fill-mode:both;
            animation-iteration-count: infinite;
        }
    }
}

/*--
    - Background Color
------------------------------------------*/
.bg-gray {
    background-color: $grey;
}
.bg-theme {
    background: $theme-color;
}
.bg-footer {
    background: $footer-color;
}
img.mfp-img {
    background-color: #f6f7f8;
    padding: 0;
    margin: 40px auto;
}
.white-bg {
    background: #ffffff;
}
.black-bg {
    background: #191919;
}
.border-bm-gray {
    border-bottom: 1px solid #e1e1e1;
}
.border-tp-gray {
    border-top: 1px solid #e1e1e1;
}
/*--
    - Social Color
------------------------------------------*/
.facebook {
    background-color: #3B5997;
    color: $white;
    &:hover {
        background-color: #3B5997;
        color: $white;
    }
}
.twitter {
    background-color: #1DA1F2;
    color: $white;
    &:hover {
        background-color: #1DA1F2;
        color: $white;
    }
}
.linkedin {
    background-color: #0077B5;
    color: $white;
    &:hover {
        background-color: #0077B5;
        color: $white;
    }
}
.pinterest {
    background-color: #BD081C;
    color: $white;
    &:hover {
        background-color: #BD081C;
        color: $white;
    }
}
.google-plus {
    background-color: #D11718;
    color: $white;
    &:hover {
        background-color: #D11718;
        color: $white;
    }
}
.behance {
    background-color: #005CFF;
    color: $white;
    &:hover {
        background-color: #005CFF;
        color: $white;
    }
}
.dribbble {
    background-color: #E84C88;
    color: $white;
    &:hover {
        background-color: #E84C88;
        color: $white;
    }
}
.github {
    background-color: #323131;
    color: $white;
    &:hover {
        background-color: #323131;
        color: $white;
    }
}
.instagram {
    background-color: #DC3472;
    color: $white;
    &:hover {
        background-color: #DC3472;
        color: $white;
    }
}
.medium {
    background-color: #00AB6C;
    color: $white;
    &:hover {
        background-color: #00AB6C;
        color: $white;
    }
}
.reddit {
    background-color: #FF4501;
    color: $white;
    &:hover {
        background-color: #FF4501;
        color: $white;
    }
}
.skype {
    background-color: #00AFF0;
    color: $white;
    &:hover {
        background-color: #00AFF0;
        color: $white;
    }
}
.slack {
    background-color: #63C1A0;
    color: $white;
    &:hover {
        background-color: #63C1A0;
        color: $white;
    }
}
.snapchat {
    background-color: #FFFC00;
    color: $white;
    &:hover {
        background-color: #FFFC00;
        color: $white;
    }
}
.tumblr {
    background-color: #01273A;
    color: $white;
    &:hover {
        background-color: #01273A;
        color: $white;
    }
}
.vimeo {
    background-color: #00ADEF;
    color: $white;
    &:hover {
        background-color: #00ADEF;
        color: $white;
    }
}
.youtube {
    background-color: #FE0002;
    color: $white;
    &:hover {
        background-color: #FE0002;
        color: $white;
    }
}
.rss {
    background-color: #eb8314;
    color: $white;
    &:hover {
        background-color: #eb8314;
        color: $white;
    }
}

/*-- Tab Content & Pane Fix --*/
.tab-content {
    width: 100%;
    & .tab-pane {
        display: block;
        height: 0;
        max-width: 100%;
        visibility: hidden;
        overflow: hidden;
        opacity: 0;
        &.active {
            height: auto;
            visibility: visible;
            opacity: 1;
            overflow: visible;
        }
    }
}

/*--
    - Product Full Width
------------------------------------------*/
.full-width-product-wrap {
    & > [class*="col-"], & > [class*="col"] {
        &:nth-child(1) {
            flex: 1 0 450px;
            max-width: 450px;
    
            // Responsive
            @media #{$laptop-device}{
                flex: 1 0 350px;
                max-width: 350px;
            }
            @media #{$desktop-device}{
                flex: 1 0 350px;
                max-width: 350px;
            }
            @media #{$tablet-device}{
                flex: 0 0 100%;
                max-width: 100%;
            }
            @media #{$large-mobile}{
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        &:nth-child(2) {
            flex: 0 0 calc(100% - 450px);
            max-width: calc(100% - 450px);
    
            // Responsive
            @media #{$laptop-device}{
                flex: 0 0 calc(100% - 350px);
                max-width: calc(100% - 350px);
            }
            @media #{$desktop-device}{
                flex: 0 0 calc(100% - 350px);
                max-width: calc(100% - 350px);
            }
            @media #{$tablet-device}{
                flex: 0 0 100%;
                max-width: 100%;
            }
            @media #{$large-mobile}{
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
    
    &.desktop-full-width {
        & > [class*="col-"], & > [class*="col"] {
            &:nth-child(1) {
                
                // Responsive
                @media #{$desktop-device}{
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
            &:nth-child(2) {
                
                // Responsive
                @media #{$desktop-device}{
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
    }
}

.laptop-3-column {
    & > [class*="col-"], & > [class*="col"] {
        
        // Responsive
        @media #{$laptop-device} {
            flex: 1 0 33.3333%;
            max-width: 33.3333%;
        }
    }
}

/*-- 
    - Page Pagination
------------------------------------------*/
.paginatoin-area {
    
}
.page-pagination {
    background: #fff6f6;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px 20px;
    & a {
        color: $theme-color;
        &:hover {
            color: #222;
        }
    }
    & ul {
        display: flex;
        justify-content: center;
    }
    & li {
        font-size: 16px;
        line-height: 24px;
        font-family: $heading-font;
        color: $heading-color;
        text-align: center;
        margin: 2px 3px;
        & a {
            color: #333;
            padding: 10px;
            border-radius: 50px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
        }
        // Responsive
        @media #{$small-mobile}{
            font-size: 14px;
            & a {
                padding: 9px;
                width: 35px;
                height: 35px;
                & i {
                    line-height: 24px;
                }
            }
        }
        &:hover {
            & a {
                color: $theme-color;
                background-color: $white;
                box-shadow: 0 0 10px rgba(0,0,0,0.5);
            }
        }
        &.active {
            & a {
                color: $theme-color;
                background-color: $white;
                box-shadow: 0 0 10px rgba(0,0,0,.5);
            }
        }
        
    }
}

/*-- Breadcrumb --*/


/*-- Category Page Title --*/
.category-page-title {
    & h4 {
        margin: 0;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 18px;
        color: $heading-color;
    }
}
.breadcrumb-item + .breadcrumb-item {
    padding-left: 0;
}
/*--
    - Button
------------------------------------------*/
.btn,.btn-2 {
    border: 1px solid #626262;
    line-height: 24px;
    padding: 10px 40px;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    color: $heading-color;
    overflow: hidden;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease 0s;
    
    &:focus{
        outline: none;
    }
    
    // Before & After Selector
    &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 0;
        width: 150%;
        background-color: $heading-color;
        transition: all 0.3s ease 0s;
        content: "";
        transform: translateX(-50%) translateY(-50%) rotate(-30deg);
        opacity: 0;
        z-index: -1;
    }

    // Hover
    &:hover {
        color: $theme-color;

        &::before {
            height: 300%;
            opacity: 1;
        }

    }

    // Hover
    &.hover-theme {

        // Before & After Selector
        &::before {
            background-color: $theme-color;
        }
        &:hover {
            border-color: $theme-color;
            color: $heading-color;

            &::before {
                height: 300%;
                opacity: 1;
            }

        }
    }
    
    &.btn-circle {
        border-radius: 50px;
    }
    &.btn-radius {
        border-radius: 5px;
    }
    &.btn-square {
        border-radius: 0;
    }
    
    &.btn-text {
        border-radius: 0;
        padding: 0;
        line-height: 20px;
        border: none;
        &::before {
            display: none;
        }
        // Hover
        &.hover-theme {
            &:hover {
                color: $theme-color;
            }
        }
    }
    &.btn-underline {
        text-decoration: underline;
    }
    
    &.btn-large {
        padding: 15px 40px;
        font-size: 16px;

        // Responsive
        @media #{$large-mobile}{
            font-size: 12px;
            padding: 7px 25px;
        }
        @media #{$small-mobile}{
            font-size: 11px;
            padding: 4px 15px;
            margin-top: 15px;
        }
    }
    &.btn-medium {
        padding: 7px 20px;
        font-size: 12px;

        // Responsive
        @media #{$large-mobile}{
            font-size: 12px;
            padding: 7px 25px;
        }
        @media #{$small-mobile}{
            font-size: 11px;
            padding: 4px 15px;
            margin-top: 15px;
        }
    }
    &.btn-small {
        padding: 4px 15px;
        font-size: 11px;
    }

    // Responsive
    @media #{$large-mobile}{
        font-size: 12px;
        padding: 7px 25px;
    }
    @media #{$small-mobile}{
        font-size: 11px;
        padding: 4px 15px;
    }
}
.btn-2 {
    padding: 8px 30px;
}

/*--
    - Blockquote
------------------------------------------*/
.blockquote {
    padding: 30px 20px;
    position: relative;
    color: #ffffff;
    font-size: 15px;

    // Responsive
    @media #{$large-mobile}{
        padding: 30px 15px 0 15px;
    }
    @media #{$small-mobile}{
        padding: 20px;
    }
    & p {
        font-size: 14px;
        color: $theme-color;
    }
}

/*--
    Sidebar Widget Css
----------------------------------*/

.widget-title {
    margin-bottom: 20px;
    & h4 {
        font-weight: 600;
    }
}

.widget-search-form {
    position: relative;
    margin-top: 20px;
    & input {
        border-bottom: 1px solid #ddd;
        position: relative;
        border-top: none;
        border-left: none;
        border-right: none;
        width: 100%;
        padding: 0 40px 10px 10px; 
    }
    & button {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        background: transparent;
        &:hover {
            color: $theme-color;
        }
        
    }
}

.widget-related-wrap { 
    & .single-related {
        position: relative;
        padding-left: 115px;
        min-height: 105px;
        margin-bottom: 20px;
        padding-top: 10px;
        overflow: hidden;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
    & .single-related-image {
        position: absolute;
        left: 0;
        top: 5px;
        overflow: hidden;
        max-width: 100px;
    }
    & .single-related-contents {
        & h5 {
            font-weight: 600;
            font-size: 16px;
        }
        & p {
            font-weight: 13px;
        }
    }
}
.archives {
    & li {
        position: relative;
        padding-left: 10px;
        margin-bottom: 10px;
        font-weight: 500;
        &::before {
            position: absolute;
            content: '';
            left: 0;
            top: 50%;
            background: #222;
            height: 3px;
            width: 3px;
            border-radius: 50%;
        }
        & a {
            & span {
                margin-left: 30px;
            }
        }
    }
}

.justify-content-between {
    & [class*="col"], & [class*="col-"] {
        flex-grow: 0;
    }
}
.black-ovelay {
    position: relative;
    &::before {
        background: rgba(0, 0, 0, 0.8);
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        
    }
}
/*-- Overlay styles --*/
[data-overlay],
[data-secondary-overlay],
[data-black-overlay],
[data-white-overlay] {
	position: relative;
}

[data-overlay]>div,
[data-overlay]>*,
[data-secondary-overlay]>div,
[data-secondary-overlay]>*,
[data-black-overlay]>div,
[data-black-overlay]>*,
[data-white-overlay]>div,
[data-white-overlay]>* {
	position: relative;
	z-index: 2;
}

[data-overlay]:before,
[data-secondary-overlay]:before,
[data-black-overlay]:before,
[data-white-overlay]:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 2;
}

[data-overlay]:before {
	background-color: $theme-color;
}

[data-secondary-overlay]:before {
	background-color: #6564db;
}

[data-black-overlay]:before {
	background-color: #000000;
}

[data-white-overlay]:before {
	background-color: #ffffff;
}

@for $i from 1 through 10 {

	[data-overlay="#{$i}"]:before,
	[data-secondary-overlay="#{$i}"]:before,
	[data-black-overlay="#{$i}"]:before,
	[data-white-overlay="#{$i}"]:before {
		opacity: #{$i * 0.10};
	}
}