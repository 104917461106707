/*--
    22. My Account Page Css
--------------------------*/
.dashboard-upper-info [class*="col-"]:not(:last-child) {
    & .d-single-info {
        border-right: 1px solid #ebebeb;
        padding: 30px 20px;
        & p {
            margin-bottom: 0;
        }
        @media #{$tablet-device}{
            border-right: medium none;
            padding: 0 0 20px;
        }
        @media #{$large-mobile}{
            border-right: medium none;
            padding: 0 0 20px;
        }
    }
}
.dashboard-upper-info {
        border-bottom: 1px solid #ebebeb;
        border-top: 1px solid #ebebeb;
        margin-bottom: 60px;
    & a.view-cart {
        background: $theme-color;
        color: #ffffff;
        font-size: 14px;
        padding: 5px 12px;
        text-transform: capitalize;
        &:hover {
            background: $black;
        }
    }
    @media #{$tablet-device}{
        border-bottom: medium none;
        border-top: medium none;
    }
    @media #{$large-mobile}{
        border-bottom: medium none;
        border-top: medium none;
    }
}
.user-name {
   & span {
        color: #303030;
        font-size: 16px;
        font-weight: 600;
    }
}
.dashboard-list {
    & li {
        & a {
            border-bottom: 1px solid #ebebeb;
            color: #222222;
            display: block;
            font-size: 14px;
            padding: 12px 0;
            text-transform: capitalize;
        }
        &:first-child {border-top: 1px solid #ebebeb;}
    }
}
.dashboard-content {
    border: 1px solid #ebebeb;
    padding: 30px;
    & h3 {
        border-bottom: 1px solid #ebebeb;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 25px;
        padding-bottom: 15px;
        text-transform: capitalize;
    }
    & .table {
        & thead {
            background: #f6f6f6 none repeat scroll 0 0;
            border-bottom: 1px solid #ebebeb;   
        }
        @media #{$tablet-device}{ 
            & td {
                min-width: 260px;
            }
        }
        @media #{$large-mobile}{ 
            & td {
                min-width: 260px;
            }
        }
    }
    & .view {
        background: $theme-color;
        color: #ffffff;
        font-size: 14px;
        padding: 5px 12px;
        text-transform: capitalize;
        &:hover {
            background: $black;
        }
    }
    @media #{$tablet-device}{
        margin-top: 80px;
    }
    @media #{$large-mobile}{
        margin-top: 60px;
    }
}
.billing-address {
  margin-bottom: 10px;
}
.biller-name {
  margin-bottom: 0;
  margin-top: 10px;
}
.account-login-form{
    & .custom-checkbox {
        display: flex;
    }
    & input[type="checkbox"] {
      height: 20px;
      margin: 0 10px 0 0;
      position: relative;
      top: 3px;
      width: auto;
    }
    & .example {
        margin-bottom: 15px;
    }
    & .default-btn {
        margin-top: 15px;
        padding: 6px 28px;
    }
}
.account-input-box {
    & label {
        display: block;
        font-size: 15px;
        margin-bottom: 3px;
    }
    & input {
        width: 100%;
        margin-bottom: 12px;
        border: 1px solid #ddd;
        padding: 4px 10px;
        font-size: 15px;
    }
}
