/*-----------------------------------------------------------------------------------

    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Theme default CSS
        - Paginatoin Css
    02. Header Css
        - Header Bottom Css
        - Main Menu Css
        - Right Blok Box Css
        - Shopping Cart Css
        - Mobile Menu Css
    03. Categories Menu Css
    04. Hero Css
    05. Support Css
    06. Product Area Css
    07. Product Details Css
    08. Testimonial Css
    09. Banner Area Css
    10. Blog Area Css
    11. Footer Css
    12. Breadcrumb Style
    13. Widget Css   
    14. Deals Offer Css
    15. Brand Css
    16. About US css
    17. Contact Us Css
    18. Cart Page Css
    19. Checkout Page Css
    20. Error 404 Page Css
    21. Compare Page Css
    22. My Account Page Css
    23. Login And Register Css

-----------------------------------------------------------------------------------*/
/* transition */
/* flex */
/* transform */
/* opacity */
/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900");
/*-- Common Style --*/
*, *::after, *::before {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  background-color: #ffffff;
  line-height: 23px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  visibility: visible;
  font-family: "Rubik", sans-serif;
  color: #454545;
  position: relative;
}

h1, h2, h3, h4, h5, h6 {
  color: #3a3a3a;
  font-family: "Rubik", sans-serif;
  margin-top: 0;
}

h1 {
  font-size: 48px;
  line-height: 55px;
}

h2 {
  font-size: 36px;
  line-height: 36px;
}

h3 {
  font-size: 24px;
  line-height: 30px;
}

h4 {
  font-size: 20px;
  line-height: 28px;
}

h5 {
  font-size: 16px;
  line-height: 20px;
}

h6 {
  font-size: 14px;
  line-height: 18px;
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 34px;
    line-height: 42px;
  }
  h1 br {
    display: none;
  }
}

@media only screen and (max-width: 479px) {
  h1 {
    font-size: 26px;
    line-height: 32px;
  }
  h1 br {
    display: none;
  }
}

p {
  font-size: 14px;
}

p:last-child {
  margin-bottom: 0;
}

a, button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: #80BB01;
}

a, button, img, input, span {
  transition: all 0.3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button, input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

button:focus,
a:focus {
  outline: none;
  box-shadow: none;
}

.btn, .btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.header-sticky.is-sticky {
  position: fixed;
  width: 100%;
  z-index: 999999999;
  top: 0;
}

.header-sticky.bg-white.is-sticky {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  z-index: 999999999;
  top: 0;
}

/*-- 
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section {
  float: left;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .container {
    width: 450px;
  }
}

@media only screen and (max-width: 479px) {
  .container {
    width: 300px;
  }
}

.bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 600;
}

.medium {
  font-weight: 500;
}

.light {
  font-weight: 300;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
  margin: 0;
}

.row-8 {
  margin-right: -8px;
  margin-left: -8px;
}

/*-- Container Fluid --*/
.container-ext {
  max-width: 1400px;
  margin: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .container-ext {
    max-width: 1400px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .container-ext {
    max-width: 1400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container-ext {
    max-width: 1400px;
  }
}

@media only screen and (max-width: 767px) {
  .container-ext {
    max-width: 1400px;
  }
}

@media only screen and (max-width: 479px) {
  .container-ext {
    max-width: 1400px;
  }
}

/*--
    - Paginatoin Css
---------------------------------*/
.pagination-box {
  display: flex;
  margin-top: 40px;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 10px;
}

@media only screen and (max-width: 767px) {
  .pagination-box {
    margin-top: 40px;
  }
}

.pagination-box li {
  display: inline-block;
  margin: 0 4px;
}

.pagination-box li.active a {
  color: #ffffff;
  background: #80BB01;
}

.pagination-box li a {
  color: #333333;
  display: block;
  padding: 0 12px;
  height: 30px;
  background: #f1f1f1;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  border-radius: 3px;
}

.pagination-box li a:hover {
  color: #ffffff;
  background: #80BB01;
}

.pagination-box li a span {
  font-size: 18px;
}

.border-bm-1 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.border-tp-1 {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .d-mt-20 {
    margin-top: 20px;
  }
  .d-mt-30 {
    margin-top: 30px;
  }
}

/*-- 
    - Section Padding
-------------------------------------*/
.section-ptb {
  padding: 60px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-ptb {
    padding: 60px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-ptb {
    padding: 60px 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-ptb {
    padding: 60px 0;
  }
}

@media only screen and (max-width: 479px) {
  .section-ptb {
    padding: 60px 0;
  }
}

.section-pt {
  padding-top: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pt {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pt {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pt {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pt {
    padding-top: 60px;
  }
}

.section-pt-100 {
  padding-top: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pt-100 {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pt-100 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pt-100 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pt-100 {
    padding-top: 60px;
  }
}

.section-pt-60 {
  padding-top: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pt-60 {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pt-60 {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pt-60 {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pt-60 {
    padding-top: 20px;
  }
}

.section-pt-30 {
  padding-top: 30px;
}

.section-pb {
  padding-bottom: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pb {
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pb {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pb {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pb {
    padding-bottom: 60px;
  }
}

.section-pb-100 {
  padding-bottom: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pb-100 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pb-100 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pb-100 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pb-100 {
    padding-bottom: 60px;
  }
}

.section-pb-40 {
  padding-bottom: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-pb-40 {
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-pb-40 {
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .section-pb-40 {
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 479px) {
  .section-pb-40 {
    padding-bottom: 0px;
  }
}

.section-pb-30 {
  padding-bottom: 30px;
}

.bg-image {
  background-size: cover;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder, textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

/*-- 
    Scroll Up 
-----------------------------------------*/
#scrollUp {
  width: 40px;
  height: 40px;
  background-color: #80BB01;
  border: 3px solid #eeeeee;
  color: #ffffff;
  right: 20px;
  bottom: 60px;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  z-index: 9811 !important;
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    display: none !important;
  }
}

#scrollUp i {
  display: block;
  line-height: 34px;
  font-size: 16px;
}

#scrollUp:hover i {
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

/*--
    - Background Color
------------------------------------------*/
.bg-gray {
  background-color: #F6F7F9;
}

.bg-theme {
  background: #80BB01;
}

.bg-footer {
  background: #1a1a1a;
}

img.mfp-img {
  background-color: #f6f7f8;
  padding: 0;
  margin: 40px auto;
}

.white-bg {
  background: #ffffff;
}

.black-bg {
  background: #191919;
}

.border-bm-gray {
  border-bottom: 1px solid #e1e1e1;
}

.border-tp-gray {
  border-top: 1px solid #e1e1e1;
}

/*--
    - Social Color
------------------------------------------*/
.facebook {
  background-color: #3B5997;
  color: #ffffff;
}

.facebook:hover {
  background-color: #3B5997;
  color: #ffffff;
}

.twitter {
  background-color: #1DA1F2;
  color: #ffffff;
}

.twitter:hover {
  background-color: #1DA1F2;
  color: #ffffff;
}

.linkedin {
  background-color: #0077B5;
  color: #ffffff;
}

.linkedin:hover {
  background-color: #0077B5;
  color: #ffffff;
}

.pinterest {
  background-color: #BD081C;
  color: #ffffff;
}

.pinterest:hover {
  background-color: #BD081C;
  color: #ffffff;
}

.google-plus {
  background-color: #D11718;
  color: #ffffff;
}

.google-plus:hover {
  background-color: #D11718;
  color: #ffffff;
}

.behance {
  background-color: #005CFF;
  color: #ffffff;
}

.behance:hover {
  background-color: #005CFF;
  color: #ffffff;
}

.dribbble {
  background-color: #E84C88;
  color: #ffffff;
}

.dribbble:hover {
  background-color: #E84C88;
  color: #ffffff;
}

.github {
  background-color: #323131;
  color: #ffffff;
}

.github:hover {
  background-color: #323131;
  color: #ffffff;
}

.instagram {
  background-color: #DC3472;
  color: #ffffff;
}

.instagram:hover {
  background-color: #DC3472;
  color: #ffffff;
}

.medium {
  background-color: #00AB6C;
  color: #ffffff;
}

.medium:hover {
  background-color: #00AB6C;
  color: #ffffff;
}

.reddit {
  background-color: #FF4501;
  color: #ffffff;
}

.reddit:hover {
  background-color: #FF4501;
  color: #ffffff;
}

.skype {
  background-color: #00AFF0;
  color: #ffffff;
}

.skype:hover {
  background-color: #00AFF0;
  color: #ffffff;
}

.slack {
  background-color: #63C1A0;
  color: #ffffff;
}

.slack:hover {
  background-color: #63C1A0;
  color: #ffffff;
}

.snapchat {
  background-color: #FFFC00;
  color: #ffffff;
}

.snapchat:hover {
  background-color: #FFFC00;
  color: #ffffff;
}

.tumblr {
  background-color: #01273A;
  color: #ffffff;
}

.tumblr:hover {
  background-color: #01273A;
  color: #ffffff;
}

.vimeo {
  background-color: #00ADEF;
  color: #ffffff;
}

.vimeo:hover {
  background-color: #00ADEF;
  color: #ffffff;
}

.youtube {
  background-color: #FE0002;
  color: #ffffff;
}

.youtube:hover {
  background-color: #FE0002;
  color: #ffffff;
}

.rss {
  background-color: #eb8314;
  color: #ffffff;
}

.rss:hover {
  background-color: #eb8314;
  color: #ffffff;
}

/*-- Tab Content & Pane Fix --*/
.tab-content {
  width: 100%;
}

.tab-content .tab-pane {
  display: block;
  height: 0;
  max-width: 100%;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

/*--
    - Product Full Width
------------------------------------------*/
.full-width-product-wrap > [class*="col-"]:nth-child(1), .full-width-product-wrap > [class*="col"]:nth-child(1) {
  flex: 1 0 450px;
  max-width: 450px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .full-width-product-wrap > [class*="col-"]:nth-child(1), .full-width-product-wrap > [class*="col"]:nth-child(1) {
    flex: 1 0 350px;
    max-width: 350px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .full-width-product-wrap > [class*="col-"]:nth-child(1), .full-width-product-wrap > [class*="col"]:nth-child(1) {
    flex: 1 0 350px;
    max-width: 350px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .full-width-product-wrap > [class*="col-"]:nth-child(1), .full-width-product-wrap > [class*="col"]:nth-child(1) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .full-width-product-wrap > [class*="col-"]:nth-child(1), .full-width-product-wrap > [class*="col"]:nth-child(1) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.full-width-product-wrap > [class*="col-"]:nth-child(2), .full-width-product-wrap > [class*="col"]:nth-child(2) {
  flex: 0 0 calc(100% - 450px);
  max-width: calc(100% - 450px);
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .full-width-product-wrap > [class*="col-"]:nth-child(2), .full-width-product-wrap > [class*="col"]:nth-child(2) {
    flex: 0 0 calc(100% - 350px);
    max-width: calc(100% - 350px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .full-width-product-wrap > [class*="col-"]:nth-child(2), .full-width-product-wrap > [class*="col"]:nth-child(2) {
    flex: 0 0 calc(100% - 350px);
    max-width: calc(100% - 350px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .full-width-product-wrap > [class*="col-"]:nth-child(2), .full-width-product-wrap > [class*="col"]:nth-child(2) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .full-width-product-wrap > [class*="col-"]:nth-child(2), .full-width-product-wrap > [class*="col"]:nth-child(2) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .full-width-product-wrap.desktop-full-width > [class*="col-"]:nth-child(1), .full-width-product-wrap.desktop-full-width > [class*="col"]:nth-child(1) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .full-width-product-wrap.desktop-full-width > [class*="col-"]:nth-child(2), .full-width-product-wrap.desktop-full-width > [class*="col"]:nth-child(2) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .laptop-3-column > [class*="col-"], .laptop-3-column > [class*="col"] {
    flex: 1 0 33.3333%;
    max-width: 33.3333%;
  }
}

/*-- 
    - Page Pagination
------------------------------------------*/
.page-pagination {
  background: #fff6f6;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 20px;
}

.page-pagination a {
  color: #80BB01;
}

.page-pagination a:hover {
  color: #222;
}

.page-pagination ul {
  display: flex;
  justify-content: center;
}

.page-pagination li {
  font-size: 16px;
  line-height: 24px;
  font-family: "Rubik", sans-serif;
  color: #3a3a3a;
  text-align: center;
  margin: 2px 3px;
}

.page-pagination li a {
  color: #333;
  padding: 10px;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (max-width: 479px) {
  .page-pagination li {
    font-size: 14px;
  }
  .page-pagination li a {
    padding: 9px;
    width: 35px;
    height: 35px;
  }
  .page-pagination li a i {
    line-height: 24px;
  }
}

.page-pagination li:hover a {
  color: #80BB01;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.page-pagination li.active a {
  color: #80BB01;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/*-- Breadcrumb --*/
/*-- Category Page Title --*/
.category-page-title h4 {
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: #3a3a3a;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}

/*--
    - Button
------------------------------------------*/
.btn, .btn-2 {
  border: 1px solid #626262;
  line-height: 24px;
  padding: 10px 40px;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  color: #3a3a3a;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.btn:focus, .btn-2:focus {
  outline: none;
}

.btn::before, .btn-2::before {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 0;
  width: 150%;
  background-color: #3a3a3a;
  transition: all 0.3s ease 0s;
  content: "";
  transform: translateX(-50%) translateY(-50%) rotate(-30deg);
  opacity: 0;
  z-index: -1;
}

.btn:hover, .btn-2:hover {
  color: #80BB01;
}

.btn:hover::before, .btn-2:hover::before {
  height: 300%;
  opacity: 1;
}

.btn.hover-theme::before, .btn-2.hover-theme::before {
  background-color: #80BB01;
}

.btn.hover-theme:hover, .btn-2.hover-theme:hover {
  border-color: #80BB01;
  color: #3a3a3a;
}

.btn.hover-theme:hover::before, .btn-2.hover-theme:hover::before {
  height: 300%;
  opacity: 1;
}

.btn.btn-circle, .btn-2.btn-circle {
  border-radius: 50px;
}

.btn.btn-radius, .btn-2.btn-radius {
  border-radius: 5px;
}

.btn.btn-square, .btn-2.btn-square {
  border-radius: 0;
}

.btn.btn-text, .btn-2.btn-text {
  border-radius: 0;
  padding: 0;
  line-height: 20px;
  border: none;
}

.btn.btn-text::before, .btn-2.btn-text::before {
  display: none;
}

.btn.btn-text.hover-theme:hover, .btn-2.btn-text.hover-theme:hover {
  color: #80BB01;
}

.btn.btn-underline, .btn-2.btn-underline {
  text-decoration: underline;
}

.btn.btn-large, .btn-2.btn-large {
  padding: 15px 40px;
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .btn.btn-large, .btn-2.btn-large {
    font-size: 12px;
    padding: 7px 25px;
  }
}

@media only screen and (max-width: 479px) {
  .btn.btn-large, .btn-2.btn-large {
    font-size: 11px;
    padding: 4px 15px;
    margin-top: 15px;
  }
}

.btn.btn-medium, .btn-2.btn-medium {
  padding: 7px 20px;
  font-size: 12px;
}

@media only screen and (max-width: 767px) {
  .btn.btn-medium, .btn-2.btn-medium {
    font-size: 12px;
    padding: 7px 25px;
  }
}

@media only screen and (max-width: 479px) {
  .btn.btn-medium, .btn-2.btn-medium {
    font-size: 11px;
    padding: 4px 15px;
    margin-top: 15px;
  }
}

.btn.btn-small, .btn-2.btn-small {
  padding: 4px 15px;
  font-size: 11px;
}

@media only screen and (max-width: 767px) {
  .btn, .btn-2 {
    font-size: 12px;
    padding: 7px 25px;
  }
}

@media only screen and (max-width: 479px) {
  .btn, .btn-2 {
    font-size: 11px;
    padding: 4px 15px;
  }
}

.btn-2 {
  padding: 8px 30px;
}

/*--
    - Blockquote
------------------------------------------*/
.blockquote {
  padding: 30px 20px;
  position: relative;
  color: #ffffff;
  font-size: 15px;
}

@media only screen and (max-width: 767px) {
  .blockquote {
    padding: 30px 15px 0 15px;
  }
}

@media only screen and (max-width: 479px) {
  .blockquote {
    padding: 20px;
  }
}

.blockquote p {
  font-size: 14px;
  color: #80BB01;
}

/*--
    Sidebar Widget Css
----------------------------------*/
.widget-title {
  margin-bottom: 20px;
}

.widget-title h4 {
  font-weight: 600;
}

.widget-search-form {
  position: relative;
  margin-top: 20px;
}

.widget-search-form input {
  border-bottom: 1px solid #ddd;
  position: relative;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 100%;
  padding: 0 40px 10px 10px;
}

.widget-search-form button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: transparent;
}

.widget-search-form button:hover {
  color: #80BB01;
}

.widget-related-wrap .single-related {
  position: relative;
  padding-left: 115px;
  min-height: 105px;
  margin-bottom: 20px;
  padding-top: 10px;
  overflow: hidden;
}

.widget-related-wrap .single-related:last-child {
  margin-bottom: 0;
}

.widget-related-wrap .single-related-image {
  position: absolute;
  left: 0;
  top: 5px;
  overflow: hidden;
  max-width: 100px;
}

.widget-related-wrap .single-related-contents h5 {
  font-weight: 600;
  font-size: 16px;
}

.widget-related-wrap .single-related-contents p {
  font-weight: 13px;
}

.archives li {
  position: relative;
  padding-left: 10px;
  margin-bottom: 10px;
  font-weight: 500;
}

.archives li::before {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  background: #222;
  height: 3px;
  width: 3px;
  border-radius: 50%;
}

.archives li a span {
  margin-left: 30px;
}

.justify-content-between [class*="col"], .justify-content-between [class*="col-"] {
  flex-grow: 0;
}

.black-ovelay {
  position: relative;
}

.black-ovelay::before {
  background: rgba(0, 0, 0, 0.8);
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
}

/*-- Overlay styles --*/
[data-overlay],
[data-secondary-overlay],
[data-black-overlay],
[data-white-overlay] {
  position: relative;
}

[data-overlay] > div,
[data-overlay] > *,
[data-secondary-overlay] > div,
[data-secondary-overlay] > *,
[data-black-overlay] > div,
[data-black-overlay] > *,
[data-white-overlay] > div,
[data-white-overlay] > * {
  position: relative;
  z-index: 2;
}

[data-overlay]:before,
[data-secondary-overlay]:before,
[data-black-overlay]:before,
[data-white-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}

[data-overlay]:before {
  background-color: #80BB01;
}

[data-secondary-overlay]:before {
  background-color: #6564db;
}

[data-black-overlay]:before {
  background-color: #000000;
}

[data-white-overlay]:before {
  background-color: #ffffff;
}

[data-overlay="1"]:before,
[data-secondary-overlay="1"]:before,
[data-black-overlay="1"]:before,
[data-white-overlay="1"]:before {
  opacity: 0.1;
}

[data-overlay="2"]:before,
[data-secondary-overlay="2"]:before,
[data-black-overlay="2"]:before,
[data-white-overlay="2"]:before {
  opacity: 0.2;
}

[data-overlay="3"]:before,
[data-secondary-overlay="3"]:before,
[data-black-overlay="3"]:before,
[data-white-overlay="3"]:before {
  opacity: 0.3;
}

[data-overlay="4"]:before,
[data-secondary-overlay="4"]:before,
[data-black-overlay="4"]:before,
[data-white-overlay="4"]:before {
  opacity: 0.4;
}

[data-overlay="5"]:before,
[data-secondary-overlay="5"]:before,
[data-black-overlay="5"]:before,
[data-white-overlay="5"]:before {
  opacity: 0.5;
}

[data-overlay="6"]:before,
[data-secondary-overlay="6"]:before,
[data-black-overlay="6"]:before,
[data-white-overlay="6"]:before {
  opacity: 0.6;
}

[data-overlay="7"]:before,
[data-secondary-overlay="7"]:before,
[data-black-overlay="7"]:before,
[data-white-overlay="7"]:before {
  opacity: 0.7;
}

[data-overlay="8"]:before,
[data-secondary-overlay="8"]:before,
[data-black-overlay="8"]:before,
[data-white-overlay="8"]:before {
  opacity: 0.8;
}

[data-overlay="9"]:before,
[data-secondary-overlay="9"]:before,
[data-black-overlay="9"]:before,
[data-white-overlay="9"]:before {
  opacity: 0.9;
}

[data-overlay="10"]:before,
[data-secondary-overlay="10"]:before,
[data-black-overlay="10"]:before,
[data-white-overlay="10"]:before {
  opacity: 1;
}

/*--
    02. Header Css
-----------------------*/
.transparent-header {
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 111;
  margin: auto;
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.my-account-container li {
  display: inline-block;
  margin-left: 15px;
  font-size: 12px;
  padding: 12px 0;
}

.text-color-white .my-account-container li {
  color: #ffffff;
}

.bg-gren {
  background: #0E7346;
}

/*----------- header top area start -----------*/
.header-top-settings ul li {
  cursor: pointer;
  font-size: 12px;
  position: relative;
  margin-left: 40px;
  padding: 12px 0;
  position: relative;
  margin-left: 20px;
  padding-left: 20px;
}

.header-top-settings ul li:before {
  top: 50%;
  left: 0;
  width: 1px;
  height: 15px;
  content: "";
  position: absolute;
  background-color: #ebebeb;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header-top-settings ul li span {
  cursor: auto;
  color: #fff;
  font-size: 14px;
  padding-right: 5px;
  text-transform: capitalize;
}

.header-top-settings ul li .dropdown-list {
  top: 100%;
  left: 0;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  padding: 15px 15px 20px;
  width: 100px;
  z-index: 9;
  pointer-events: none;
  background-color: #fff;
  -webkit-box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
}

.header-top-settings ul li .dropdown-list li {
  margin-left: 0;
  padding: 0;
}

.header-top-settings ul li .dropdown-list li::before {
  display: none;
}

.header-top-settings ul li .dropdown-list li a {
  color: #252525;
  font-size: 14px;
  display: block;
  padding: 5px 0 3px;
  text-transform: capitalize;
}

.header-top-settings ul li .dropdown-list li a:hover {
  color: #80BB01;
}

.header-top-settings ul li:first-child {
  margin-left: 0;
  padding-left: 0;
}

.header-top-settings ul li:first-child::before {
  display: none;
}

.header-top-settings ul li:hover .dropdown-list,
.header-top-settings ul li:hover .cart-list {
  opacity: 1;
  visibility: visible;
  pointer-events: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.text-color-white .header-top-settings ul li {
  color: #fff;
}

/*--
    - Header Bottom Css
-------------------------------*/
.logo {
  margin: 30px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .logo {
    margin: 15px 0;
  }
}

@media only screen and (max-width: 767px) {
  .logo {
    margin: 10px 0;
  }
}

/* Main Menu Css */
.main-menu-area ul > li {
  display: inline-block;
  position: relative;
  padding: 15px 0px;
  margin-right: 55px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-area ul > li {
    margin-right: 40px;
  }
}

.main-menu-area ul > li > a {
  display: block;
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  text-transform: uppercase;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.main-menu-area ul > li > a i {
  margin-left: 3px;
}

.main-menu-area ul > li > a:hover {
  color: #80BB01 !important;
}

.main-menu-area ul > li > a::before {
  content: "";
  background: #80BB01;
  width: 0%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  -ms-filter: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.main-menu-area ul > li:first-child {
  padding-left: 0;
}

.main-menu-area ul > li:hover > a::before {
  visibility: visible;
  opacity: 1;
  -ms-filter: 1;
  width: 100%;
}

.main-menu-area ul > li:hover .sub-menu, .main-menu-area ul > li:hover .mega-menu {
  visibility: visible;
  opacity: 1;
  -ms-filter: 1;
  top: 100%;
}

.main-menu-area.white_text ul > li > a {
  color: #ffffff;
}

/*--
    - Main Menu Css
---------------------*/
.main-menu-area .sub-menu {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  left: 0;
  padding: 15px;
  position: absolute;
  text-align: left;
  width: 200px;
  z-index: 99;
  top: 120%;
  visibility: hidden;
  opacity: 0;
  -ms-filter: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.main-menu-area .sub-menu > li {
  padding: 0;
  margin-right: 0px;
  display: block;
}

.main-menu-area .sub-menu > li:first-child {
  margin-bottom: 0;
}

.main-menu-area .sub-menu > li > a {
  padding: 0;
  font-weight: 400;
  margin-bottom: 8px;
  color: #333 !important;
  text-transform: capitalize;
}

.main-menu-area .sub-menu > li > a::before {
  display: none;
}

.main-menu-area .mega-menu {
  background: #ffffff;
  left: 0;
  padding: 30px 20px;
  position: absolute;
  text-align: left;
  width: 640px;
  z-index: 99;
  top: 120%;
  visibility: hidden;
  opacity: 0;
  -ms-filter: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.main-menu-area .mega-menu > li {
  width: 33.333%;
  float: left;
  padding: 0;
  margin-right: 0px;
}

.main-menu-area .mega-menu > li > a {
  padding: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
  color: #333 !important;
}

.main-menu-area .mega-menu > li > a::before {
  display: none;
}

.main-menu-area .mega-menu > li ul > li {
  display: block;
  padding: 0;
  margin-right: 0;
}

.main-menu-area .mega-menu > li ul > li a {
  padding: 0;
  color: #333 !important;
  text-transform: capitalize;
  display: block;
  font-weight: 400;
  margin-top: 8px;
}

.main-menu-area .mega-menu > li ul > li a::before {
  display: none;
}

/*--
    - Right Blok Box Css
---------------------------*/
.right-blok-box {
  justify-content: flex-end;
  margin: 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .right-blok-box {
    margin: 32px 0px 20px 0;
  }
}

@media only screen and (max-width: 767px) {
  .right-blok-box {
    margin: 32px 0px 20px 0;
  }
}

.right-blok-box a {
  font-size: 20px;
  display: inline-block;
}

.right-blok-box a .cart-total-amunt {
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
}

@media only screen and (max-width: 479px) {
  .right-blok-box a .cart-total-amunt {
    display: none;
  }
}

.cart-total {
  background: #80BB01;
  border-radius: 100%;
  color: #ffffff;
  float: left;
  font-size: 10px;
  font-weight: 500;
  height: 22px;
  line-height: 22px;
  width: 22px;
  position: absolute;
  text-align: center;
  text-transform: capitalize;
  top: -10px;
  left: -15px;
}

.user-wrap {
  padding-right: 30px;
  font-size: 20px;
  position: relative;
}

.user-wrap.box-user {
  padding: 8px 12px;
  border-radius: 3px;
  background: transparent;
  border: 2px solid #ddd;
  color: #333;
  margin-right: 20px;
}

.user-wrap.box-user #cart-total {
  background: #0E7346;
}

.box-cart-wrap .shopping-cart-wrap {
  padding: 8px 12px;
  border-radius: 3px;
  border: 2px solid #80BB01;
  background: #80BB01;
}

.box-cart-wrap .shopping-cart-wrap a:hover {
  color: #fff;
}

.box-cart-wrap #cart-total {
  background: #0E7346;
}

/*-- 
    - Shopping Cart Css
----------------------------*/
.shopping-cart-wrap {
  position: relative;
}

.shopping-cart-wrap ul.mini-cart {
  position: absolute;
  width: 320px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  right: 0;
  top: 180%;
  border-radius: 5px;
  padding: 30px;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  -ms-filter: 0;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 479px) {
  .shopping-cart-wrap ul.mini-cart {
    padding: 30px 15px;
  }
}

.shopping-cart-wrap ul.mini-cart::before {
  content: "";
  position: absolute;
  right: 40px;
  top: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 7px 6px;
  border-color: transparent transparent #ffffff transparent;
}

.shopping-cart-wrap ul.mini-cart .cart-item {
  display: flex;
  padding-bottom: 15px;
  position: relative;
}

.shopping-cart-wrap ul.mini-cart .cart-item .cart-image {
  display: block;
  width: 100px;
}

.shopping-cart-wrap ul.mini-cart .cart-item .cart-title {
  padding-left: 15px;
  width: 60%;
}

.shopping-cart-wrap ul.mini-cart .cart-item .cart-title h4 {
  font-size: 14px;
  font-weight: 400;
}

.shopping-cart-wrap ul.mini-cart .cart-item .cart-title .quanti-price-wrap {
  display: flex;
}

.shopping-cart-wrap ul.mini-cart .cart-item .cart-title .quantity {
  margin-right: 5px;
  color: #333;
}

.shopping-cart-wrap ul.mini-cart .cart-item .cart-title .price-box {
  color: #333;
  font-weight: 500;
}

.shopping-cart-wrap ul.mini-cart .cart-item .cart-title .price-box .old-price {
  color: #666;
  text-decoration: line-through;
}

.shopping-cart-wrap ul.mini-cart .cart-item .cart-title .remove_from_cart {
  background: none;
  color: #666 !important;
  display: block;
  font-size: 0;
  height: auto;
  left: auto;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  z-index: 1;
}

.shopping-cart-wrap ul.mini-cart .cart-item .cart-title .remove_from_cart::before {
  content: "\ea02";
  font-family: plaza-icon;
  font-size: 18px;
  color: #dedede;
}

.shopping-cart-wrap ul.mini-cart .subtotal-box {
  border-top: 1px solid #ddd;
  padding-top: 15px;
}

.shopping-cart-wrap ul.mini-cart .subtotal-box .subtotal-title {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.shopping-cart-wrap ul.mini-cart .subtotal-box .subtotal-title h3 {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  line-height: 20px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .shopping-cart-wrap ul.mini-cart {
    right: -30px;
    width: 280px;
  }
}

.shopping-cart-wrap:hover ul.mini-cart {
  visibility: visible;
  opacity: 1;
  -ms-filter: 1;
  top: 160%;
}

.mini-cart-btns .cart-btns {
  justify-content: space-between;
  padding-top: 15px;
  width: 100%;
}

.mini-cart-btns .cart-btns a {
  margin-bottom: 10px;
  background: #eef0f1;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  color: #333;
  display: block;
  font-size: 12px;
  font-weight: 500;
  height: 40px;
  line-height: 36px;
  padding: 0 25px;
  text-align: center;
  text-transform: uppercase;
}

.mini-cart-btns .cart-btns a:hover {
  background: #80BB01;
  border: 1px solid #80BB01;
  color: #ffffff;
}

/*---------------------------
    - Mobile Menu Css
-----------------------------*/
.mobile-header .category-toggle {
  padding: 8px 15px;
}

.mobile-menu-btn .off-canvas-btn {
  font-size: 22px;
  cursor: pointer;
  padding: 0px 12px;
  line-height: 1;
  padding-right: 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  margin-top: -3px;
}

.mobile-menu li > a {
  font-size: 15px;
  color: #252525;
  text-transform: capitalize;
  line-height: 18px;
  position: relative;
  display: inline-block;
  padding: 10px 0;
}

.mobile-menu li > a:hover {
  color: #80BB01;
}

.mobile-menu li ul li {
  border: none;
}

.mobile-menu li ul li a {
  font-size: 14px;
  text-transform: capitalize;
  padding: 10px 15px 8px;
}

.mobile-menu .has-children,
.mobile-menu .menu-item-has-children {
  position: relative;
}

.mobile-menu .has-children .menu-expand,
.mobile-menu .menu-item-has-children .menu-expand {
  line-height: 50;
  top: -5px;
  left: 95%;
  width: 30px;
  position: absolute;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.mobile-menu .has-children .menu-expand i,
.mobile-menu .menu-item-has-children .menu-expand i {
  display: block;
  position: relative;
  width: 10px;
  margin-top: 25px;
  border-bottom: 1px solid #252525;
  -webkit-transition: all 250ms ease-out;
  -o-transition: all 250ms ease-out;
  transition: all 250ms ease-out;
}

.mobile-menu .has-children .menu-expand i:before,
.mobile-menu .menu-item-has-children .menu-expand i:before {
  top: 0;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  border-bottom: 1px solid #252525;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.mobile-menu .has-children.active > .menu-expand i:before,
.mobile-menu .menu-item-has-children.active > .menu-expand i:before {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.search-box-offcanvas {
  margin-bottom: 10px;
}

.search-box-offcanvas form {
  position: relative;
}

.search-box-offcanvas form input {
  color: #666;
  font-size: 13px;
  width: 100%;
  height: 40px;
  border: none;
  padding: 0 40px 0 10px;
  background-color: #f2f2f2;
}

.search-box-offcanvas form .search-btn {
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  line-height: 42px;
  font-size: 20px;
  color: #fff;
  position: absolute;
  background: #80BB01;
  border: none;
}

/*-- 
    header middile area start 
---------------------------------*/
.hader-mid-right-box {
  display: flex;
}

.search-box-inner {
  position: relative;
}

.search-box-inner .search-field {
  width: 100%;
  height: 50px;
  border: none;
  background-color: #fff;
  border-radius: 0 5px 5px 0;
  padding: 0 55px 0 30px;
}

.search-field-wrap {
  position: relative;
  width: 415px;
}

.search-field-wrap .search-btn button {
  width: 50px;
  height: 50px;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  display: block;
  position: absolute;
  top: 50%;
  right: 0px;
  border-radius: 0 3px 3px 0;
  color: #ffffff;
  background: #80BB01;
  border: none;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.search-box-inner {
  display: flex;
  border-radius: 3px;
  background: transparent;
  border: 2px solid transparent;
}

.search-box-inner.border-2 {
  border: 2px solid #ddd;
}

.search-box-inner.border-2 .search-btn button {
  height: 54px;
  right: -2px;
  line-height: 54px;
}

.search-select-box {
  width: auto;
  position: relative;
  background: #fff;
  border-radius: 5px 0 0 5px;
}

.search-select-box::before {
  content: "";
  width: 1px;
  height: 16px;
  background: #a1a1a1;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: -10px;
  z-index: 1;
}

.search-select-box .nice-select {
  border: 0;
  background: inherit;
  height: 49px;
  line-height: 49px;
  width: 100%;
  font-size: 14px;
  padding: 0 40px 0 30px;
}

.search-select-box .nice-select ul.list {
  max-height: 300px;
  overflow: auto;
}

.search-title {
  font-size: 12px;
}

.search-title p {
  font-size: 12px;
  margin-bottom: 5px;
}

.search-title ul li {
  display: inline-block;
  margin-left: 5px;
}

.search-box-wrapper {
  max-width: 470px;
  padding: 30px 0;
}

.search-box-wrapper.full_width {
  max-width: 100%;
}

.search-box-wrapper.full_width .search-field-wrap {
  width: 100%;
}

.customer-wrap {
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
  font-size: .85714em;
}

.single-costomer-box {
  border: 2px solid #e1e1e1;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background: transparent;
  padding: 18px 15px 10px;
  margin: 0 0 0 20px;
}

.single-costomer-box:first-child {
  margin-left: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-costomer-box {
    padding: 18px 6px 10px;
    margin: 0 0 0 8px;
  }
}

.single-costomer-box .single-costomer {
  text-align: center;
  color: #77777A;
}

.single-costomer-box .single-costomer i {
  display: block;
  font-size: 24px;
  margin-bottom: 5px;
}

.single-costomer-box .single-costomer span {
  font-size: 12px;
}

.green-bg .single-costomer-box {
  border: 2px solid #158f59;
  background: #138552;
}

.green-bg .single-costomer-box .single-costomer {
  color: #ffffff;
}

/*----- mobile menu start -----*/
.mobile-navigation {
  overflow: hidden;
  max-height: 250px;
}

.off-canvas-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  z-index: 9999;
}

.mobile-menu {
  height: 100%;
  overflow: auto;
  padding-right: 30px;
  margin-right: -30px;
}

.mobile-navigation nav {
  height: 100%;
}

.off-canvas-wrapper.open {
  opacity: 1;
  visibility: visible;
}

.off-canvas-wrapper.open .off-canvas-inner-content {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.off-canvas-wrapper .off-canvas-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  content: '';
  cursor: url("../images/icon/cancel.png"), auto;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.off-canvas-wrapper .off-canvas-inner-content {
  background-color: #fff;
  width: 300px;
  padding: 30px;
  height: 100%;
  position: relative;
  -webkit-transform: translateX(calc(-100% - 50px));
  -ms-transform: translateX(calc(-100% - 50px));
  transform: translateX(calc(-100% - 50px));
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

@media only screen and (max-width: 479.98px) {
  .off-canvas-wrapper .off-canvas-inner-content {
    width: 260px;
    padding: 15px;
  }
}

.off-canvas-wrapper .btn-close-off-canvas {
  top: 0;
  left: 100%;
  width: 50px;
  height: 50px;
  font-size: 30px;
  background-color: #80BB01;
  color: #fff;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

@media only screen and (max-width: 479.98px) {
  .off-canvas-wrapper .btn-close-off-canvas {
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 40px;
  }
}

.off-canvas-wrapper .btn-close-off-canvas i {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.off-canvas-wrapper .btn-close-off-canvas:hover i {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.off-canvas-wrapper .off-canvas-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: auto;
  height: 100%;
}

.offcanvas-curreny-lang-support {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 20px 0;
  justify-content: center;
  padding-top: 15px;
}

.offcanvas-curreny-lang-support.header-top-settings ul li {
  color: #333;
  font-size: 14px;
}

.offcanvas-account-container li {
  display: inline-block;
  margin-left: 10px;
  padding-left: 10px;
  margin-bottom: 2px;
}

.offcanvas-account-container li::before {
  content: "\2022";
  color: #777;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/*--
    03. Categories Menu Css
-------------------------------*/
.categories_menu {
  position: relative;
}

.categories_title {
  height: 50px;
  line-height: 50px;
  background: #80BB01;
  -webkit-border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  border-radius: 8px 8px 0 0;
  padding: 0 30px;
  color: #fff;
  font-size: 1em;
  font-weight: 500;
  cursor: pointer;
  position: relative;
}

.categories_title .categori_toggle {
  line-height: 50px;
  color: #fff;
  font-size: 1em;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
}

.categories_title .categori_toggle::after {
  content: "\e908";
  color: #fff;
  display: inline-block;
  font-family: 'plaza-icon';
  position: absolute;
  font-size: 18px;
  line-height: 0px;
  right: 30px;
  top: 50%;
  transform: translatey(-50%);
}

.categories-menu-wrap_box .categories_title {
  height: 53px;
  line-height: 53px;
  border-radius: 0px 0px 0 0;
}

.categories-menu-wrap_box .categories_menu_toggle {
  display: none;
}

.categories_menu_toggle {
  padding: 15px 0 9px;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);
  background: #fff;
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 9;
}

.categories_menu_toggle > ul > li {
  position: relative;
}

.categories_menu_toggle > ul > li > a {
  font-size: 14px;
  line-height: 1.8;
  text-transform: capitalize;
  font-weight: 400;
  display: block;
  cursor: pointer;
  padding: 6px 30px;
}

.categories_menu_toggle > ul > li > a i {
  float: right;
  line-height: 1.8;
}

.categories_menu_toggle > ul > li:hover ul.categories_mega_menu {
  opacity: 1;
  visibility: visible;
  left: 100%;
}

.categories_menu_toggle > ul > li ul.categories_mega_menu {
  position: absolute;
  left: 110%;
  width: 210px;
  -webkit-box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.3);
  background: #fff;
  padding: 10px 20px;
  overflow: hidden;
  top: 0;
  -webkit-transition: .3s;
  transition: .3s;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
}

.categories_menu_toggle > ul > li ul.categories_mega_menu > li {
  display: block;
  border-bottom: 1px solid #ddd;
}

.categories_menu_toggle > ul > li ul.categories_mega_menu > li:last-child {
  border-bottom: none;
}

.categories_menu_toggle > ul > li ul.categories_mega_menu > li > a {
  display: block;
  padding: 8px 0;
}

.categories_menu_toggle > ul > li ul.categories_mega_menu.open {
  display: block;
  left: 0;
}

.categories-more-less {
  border-top: 1px solid #ddd;
  margin: 10px 30px 0;
}

.categories-more-less a {
  padding: 12px 0px !important;
  font-weight: 500 !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .categories_menu_toggle > ul > li {
    position: relative;
  }
  .categories_menu_toggle > ul > li > a {
    position: relative;
  }
  .categories_menu_toggle > ul > li ul.categories_mega_menu {
    display: none;
    opacity: inherit !important;
    visibility: inherit !important;
    left: inherit !important;
    width: 100%;
    top: 100%;
    max-height: 350px;
    overflow: auto;
    transition: unset;
    box-shadow: inherit;
    position: inherit;
    padding: 6px 20px 6px 40px;
    background: #f9f9f9;
  }
  .categories_menu_toggle > ul > li ul.categories_mega_menu > li {
    width: 100%;
    padding: 0;
  }
  .categories_menu_toggle > ul > li.menu_item_children > a i {
    transform: rotate(90deg);
  }
  .categories_menu_toggle > ul > li.menu_item_children.open > a i {
    transform: rotate(-90deg);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 768px) and (max-width: 991px) and (max-width: 767px), only screen and (max-width: 767px) and (max-width: 767px) {
  .categories_menu_toggle {
    display: none;
  }
}

.categories-more-less {
  cursor: pointer;
}

.categories_menu_toggle ul li a.less-show {
  display: none;
}

.categories_menu_toggle ul li.rx-change a.less-show {
  display: block;
}

.categories_menu_toggle ul li.rx-change a.more-default {
  display: none;
}

.categories_menu_toggle li.hide-child {
  display: none;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .header-sticky.is-sticky .categories-menu-wrap_box {
    display: none;
  }
}

/*-- Welcome Text Css --*/
.welcome-section .welcome-title h2 {
  font-weight: 400;
  font-size: 2.1429em;
  margin-bottom: 25px;
}

.welcome-section .welcome-title h2 strong {
  color: #80BB01;
}

.welcome-section p {
  max-width: 750px;
  margin: 30px auto;
}

.welcome-section h4 {
  font-size: 0.8571em;
  font-weight: 400;
  margin: 0px 0 0;
}

/*--
    04. Hero Css
--------------------------------*/
.hero-slider-one .swiper-slide {
  background-size: cover;
  background-position: center;
}

.hero-slider-one .gallery-top {
  height: 425px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .hero-slider-one .gallery-top {
    height: 400px;
  }
}

.hero-slider-one .gallery-thumbs {
  height: auto;
  box-sizing: border-box;
  padding: 0px 0;
  border-bottom: 1px solid #ddd;
}

.hero-slider-one .gallery-thumbs .slider-thum-text {
  display: block;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.1;
  color: #777777;
  padding: 14px 0px;
  max-width: 120px;
  margin: auto;
  cursor: pointer;
}

.hero-slider-one .gallery-thumbs .slider-thum-text:hover {
  color: #80BB01;
}

.hero-slider-one .gallery-thumbs .swiper-slide {
  width: 25%;
  height: 100%;
  border-bottom: 2px solid transparent;
}

.hero-slider-one .gallery-thumbs .swiper-slide-thumb-active {
  border-bottom: 2px solid #80BB01;
}

.hero-content-one .slider-content-text, .hero-content-two .slider-content-text {
  padding: 70px 50px;
}

.hero-content-one .slider-content-text h1, .hero-content-two .slider-content-text h1 {
  color: #ffffff;
  margin-bottom: 20px;
}

.hero-content-one .slider-content-text h2, .hero-content-two .slider-content-text h2 {
  color: #ffffff;
  margin-bottom: 40px;
}

.hero-content-one .slider-content-text p, .hero-content-two .slider-content-text p {
  color: #ffffff;
}

.hero-content-one .slider-content-text .slider-btn, .hero-content-two .slider-content-text .slider-btn {
  margin-top: 50px;
}

.hero-content-one .slider-content-text .slider-btn a, .hero-content-two .slider-content-text .slider-btn a {
  z-index: 8;
  min-width: 170px;
  max-width: 170px;
  font-size: 16px;
  line-height: 50px;
  font-weight: 500;
  color: #333333;
  letter-spacing: 0px;
  text-transform: uppercase;
  background-color: white;
  border-radius: 3px;
  text-align: center;
  min-height: 50px;
  opacity: 1;
  transform-origin: 50% 50% 0px;
  transform: translate3d(0px, 0px, 0px);
  box-shadow: #999999 0px 0px 0px 0px;
}

.hero-content-one .slider-content-text .slider-btn a:hover, .hero-content-two .slider-content-text .slider-btn a:hover {
  background: #80BB01;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .hero-content-one .slider-content-text, .hero-content-two .slider-content-text {
    padding: 50px 15px;
  }
  .hero-content-one .slider-content-text h2, .hero-content-two .slider-content-text h2 {
    font-size: 24px;
    line-height: 30px;
  }
  .hero-content-one .slider-content-text h1, .hero-content-two .slider-content-text h1 {
    font-size: 26px;
    line-height: 30px;
  }
}

.hero-slider-one .swiper-container-horizontal > .swiper-pagination-bullets, .hero-slider-one .swiper-pagination-custom, .hero-slider-one .swiper-pagination-fraction {
  bottom: 30px;
  left: 50px;
  width: 100%;
  right: auto;
  text-align: left;
}

.hero-slider-one .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.8);
}

.hero-slider-one .swiper-pagination-bullet-active {
  background: #fff;
}

/*-- Hero Two Css --*/
.hero-slider-two .single-slide, .hero-slider-three .single-slide, .hero-slider-four .single-slide {
  align-items: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  height: 398px;
  position: relative;
  z-index: 1;
}

.hero-slider-two .slider-content-text, .hero-slider-three .slider-content-text, .hero-slider-four .slider-content-text {
  padding: 70px 0px;
}

.hero-slider-two h1, .hero-slider-three h1, .hero-slider-four h1 {
  font-weight: 400;
}

.hero-slider-two h2, .hero-slider-three h2, .hero-slider-four h2 {
  font-weight: 400;
}

.hero-slider-two .slick-arrow, .hero-slider-three .slick-arrow, .hero-slider-four .slick-arrow {
  background-color: rgba(0, 0, 0, 0.6);
  border: medium none;
  border-radius: 0px;
  color: #ffffff;
  height: 40px;
  position: absolute;
  top: 50%;
  width: 40px;
  z-index: 99;
  font-size: 20px;
  visibility: hidden;
  opacity: 0;
  -ms-filter: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.hero-slider-two .slick-arrow:hover, .hero-slider-three .slick-arrow:hover, .hero-slider-four .slick-arrow:hover {
  background-color: #333;
}

@media only screen and (max-width: 479px), only screen and (max-width: 767px) {
  .hero-slider-two .slick-arrow, .hero-slider-three .slick-arrow, .hero-slider-four .slick-arrow {
    height: 40px;
    width: 40px;
  }
}

.hero-slider-two .slick-arrow.slick-next, .hero-slider-three .slick-arrow.slick-next, .hero-slider-four .slick-arrow.slick-next {
  right: 15px;
}

.hero-slider-two .slick-arrow.slick-prev, .hero-slider-three .slick-arrow.slick-prev, .hero-slider-four .slick-arrow.slick-prev {
  left: 15px;
}

.hero-slider-two:hover .slick-arrow, .hero-slider-three:hover .slick-arrow, .hero-slider-four:hover .slick-arrow {
  visibility: visible;
  opacity: 1;
  -ms-filter: 1;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.hero-slider-three .single-slide, .hero-slider-four .single-slide {
  height: 530px;
}

@media only screen and (max-width: 767px) {
  .hero-slider-three .single-slide, .hero-slider-four .single-slide {
    height: 400px;
  }
}

.hero-slider-four .hero-content-two h1 {
  color: #333;
}

.hero-slider-four .hero-content-two h2 {
  color: #333;
}

.hero-slider-four .hero-content-two p {
  color: #333;
}

.hero-slider-four .hero-content-two .slider-btn a {
  background-color: #80BB01;
  color: #fff;
}

.hero-slider-four .hero-content-two .slider-btn a:hover {
  background: #333;
  color: #ffffff;
}

.hero-slider-four .slick-dots {
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 15px;
}

.hero-slider-four .slick-dots li {
  display: inline-block;
}

.hero-slider-four .slick-dots li.slick-active button {
  background: #80BB01;
}

.hero-slider-four .slick-dots button {
  background: #ddd;
  border: medium none;
  border-radius: 50%;
  color: #ffffff;
  height: 8px;
  bottom: 0%;
  width: 8px;
  font-size: 0px;
  margin: 0 3px;
  padding: 0;
}

.slick-current {
  /*-- Hero Content One --*/
}

.slick-current .slider-content-text h2 {
  animation-name: leFadeInLeft;
  animation-duration: 1.0s;
}

.slick-current .slider-content-text h1 {
  animation-name: leFadeInLeft;
  animation-duration: 1.9s;
}

.slick-current .slider-content-text p {
  animation-name: fadeInBottom;
  animation-duration: 1.7s;
}

.slick-current .slider-content-text .slider-btn-two {
  animation-name: fadeInBottom;
  animation-duration: 1.9s;
}

.slick-current .slider-content-text .social-top {
  animation-name: fadeInBottom;
  animation-duration: 1.9s;
}

/*--
    05. Support Css
--------------------------------*/
.custom-col-service {
  padding-right: 8px;
  padding-left: 8px;
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-col-service {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-service {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-service {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 479px) {
  .custom-col-service {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.single-support {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.single-support .support-content {
  margin-left: 20px;
}

/*--
   06. Product Area Css
------------------------------*/
.single-categories-item {
  border: 1px solid #ebebeb;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  text-align: center;
  padding: 26px 11px;
}

.single-categories-item .cat-item-image {
  margin-bottom: 35px;
}

.single-categories-item .categories-title {
  position: relative;
  margin: 0;
}

.single-categories-item .categories-title h6 {
  color: #242424;
  font-weight: 500;
  text-transform: capitalize;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
}

.single-categories-item .categories-title p {
  filter: alpha(opacity=0);
  opacity: 0;
  color: #777;
  font-size: .8571em;
}

.single-categories-item:hover {
  border: 2px solid #80BB01;
}

.single-categories-item:hover .categories-title h6 {
  color: #80bb01;
  padding-bottom: 20px;
}

.single-categories-item:hover .categories-title p {
  filter: alpha(opacity=100);
  opacity: 1;
}

.product-two-row-4 .slick-track, .product-active-lg-4 .slick-track, .product-row-6-active .slick-track, .product-one-active .slick-track {
  margin-top: 0px;
  padding: 0px 0 60px;
  transform: translateY(60px);
}

.product-two-row-5 .slick-list, .product-two-row-3 .slick-list {
  margin: 0 0 -60px;
  overflow: hidden;
  padding: 0 0 60px;
  position: relative;
}

.product-col {
  padding-right: 8px;
  padding-left: 8px;
}

.single-product-wrap, .single-product-two {
  display: block;
  overflow: hidden;
  -webkit-transition: all .4s ease-out;
  -moz-transition: all .4s ease-out;
  -ms-transition: all .4s ease-out;
  -o-transition: all .4s ease-out;
  transition: all .4s ease-out;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  position: relative;
  z-index: 1;
}

.single-product-wrap .product-image, .single-product-two .product-image {
  text-align: center;
}

.single-product-wrap .product-image .onsale, .single-product-two .product-image .onsale {
  background: #e01212;
  box-shadow: none;
  color: #ffffff;
  font-family: Rubik;
  font-size: .8571em;
  font-weight: normal;
  height: 25px;
  left: 15px;
  line-height: 25px;
  min-height: initial;
  min-width: 0;
  overflow: hidden;
  padding: 0 10px;
  position: absolute;
  text-shadow: none;
  text-transform: none;
  top: 15px;
  z-index: 42;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.single-product-wrap .product-button .add-to-wishlist, .single-product-two .product-button .add-to-wishlist {
  clear: none;
  top: 15px;
  padding: 0;
  position: absolute;
  right: 20px;
  text-align: center;
  z-index: 95;
  margin: 0;
  font-size: 16px;
}

.single-product-wrap .product-content, .single-product-two .product-content {
  text-align: center;
}

.single-product-wrap .product-content .price-box, .single-product-two .product-content .price-box {
  color: #80bb01;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  width: 100%;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.single-product-wrap .product-content .price-box .new-price, .single-product-two .product-content .price-box .new-price {
  background: none;
  font-size: 1em;
  text-decoration: none;
  color: #80bb01;
  font-size: 16px;
  font-weight: 500;
}

.single-product-wrap .product-content .price-box .old-price, .single-product-two .product-content .price-box .old-price {
  color: #777;
  display: inline-block;
  font-size: .875em;
  font-weight: normal;
  margin-left: 4px;
  text-decoration: line-through;
  filter: alpha(opacity=100);
  opacity: 1;
}

.single-product-wrap .product-content .product-name, .single-product-two .product-content .product-name {
  color: #333333;
  font-size: 1em;
  margin-bottom: 15px;
  font-weight: normal;
}

.single-product-wrap .product-content .product-rating, .single-product-two .product-content .product-rating {
  margin-bottom: 28px;
}

.single-product-wrap .product-content .product-rating ul li, .single-product-two .product-content .product-rating ul li {
  display: inline-block;
}

.single-product-wrap .product-content .product-rating ul li a, .single-product-two .product-content .product-rating ul li a {
  padding-right: 0px;
  color: #E7C643;
  font-size: 16px;
}

.single-product-wrap .product-content .product-rating ul li.bad-reting a, .single-product-two .product-content .product-rating ul li.bad-reting a {
  color: #CFCFCF;
}

.single-product-wrap .product-button-action, .single-product-two .product-button-action {
  height: 58px;
  filter: alpha(opacity=0);
  opacity: 0;
  margin-top: -58px;
  -webkit-transition: all .4s ease-out;
  -moz-transition: all .4s ease-out;
  -ms-transition: all .4s ease-out;
  -o-transition: all .4s ease-out;
  transition: all .4s ease-out;
}

.single-product-wrap .product-button-action .add-to-cart, .single-product-two .product-button-action .add-to-cart {
  background: #80bb01;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.025em;
  height: 40px;
  line-height: 40px;
  padding: 0 35px;
  text-shadow: none;
  text-transform: capitalize;
  text-align: center;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  padding: 0 18px;
}

.single-product-wrap .product-button-action .add-to-cart:hover, .single-product-two .product-button-action .add-to-cart:hover {
  background: #5d8801;
  border-color: #5d8801;
  color: #fff;
}

.single-product-wrap:hover, .single-product-two:hover {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: -58px;
  z-index: 999;
}

.single-product-wrap:hover .product-button-action, .single-product-two:hover .product-button-action {
  filter: alpha(opacity=100);
  opacity: 1;
  margin-top: 0;
}

.single-product-two .product-image {
  float: left;
  width: 40%;
}

.single-product-two .product-content {
  float: left;
  padding: 10px 5px 5px 15px;
  width: 60%;
  display: block;
  text-align: left;
}

.single-product-two .product-button-action {
  height: 58px;
  filter: alpha(opacity=0);
  opacity: 0;
  margin-top: -58px;
}

.single-product-two:hover {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 0px;
  z-index: 99999999999;
}

.single-product-two:hover .product-button-action {
  filter: alpha(opacity=100);
  opacity: 1;
  margin-top: -58px;
}

.product-image {
  position: relative;
}

.countdown-deals {
  text-align: center;
  display: flex;
  background: #80bb01;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  justify-content: space-evenly;
  height: 50px;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
}

.countdown-deals .single-countdown {
  color: #fff;
  display: inline-block;
  line-height: 1;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
}

.countdown-deals .single-countdown .single-countdown__time {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
}

.countdown-deals .single-countdown .single-countdown__text {
  font-size: 10px;
}

.view-all-product a {
  color: #80BB01;
}

.tabs-categorys-list .menu-tabs, .tabs-categorys-list .menu-tabs-two {
  margin: 0;
  padding: 0 90px 0 0;
  list-style: none;
  justify-content: flex-end;
  margin-top: 6px;
}

.tabs-categorys-list .menu-tabs li, .tabs-categorys-list .menu-tabs-two li {
  padding-right: 25px;
}

.tabs-categorys-list .menu-tabs li:last-child, .tabs-categorys-list .menu-tabs-two li:last-child {
  padding-right: 0;
}

.tabs-categorys-list .menu-tabs li .active, .tabs-categorys-list .menu-tabs-two li .active {
  color: #80BB01;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tabs-categorys-list .menu-tabs, .tabs-categorys-list .menu-tabs-two {
    padding: 0 52px 0 0;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-top: 0px;
  }
  .tabs-categorys-list .menu-tabs li, .tabs-categorys-list .menu-tabs-two li {
    padding-right: 10px;
  }
}

.tabs-categorys-list .menu-tabs-two {
  padding: 0 15px 0 0;
}

.tabs-categorys-list-two .menu-tabs {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebebeb;
}

.tabs-categorys-list-two .menu-tabs li {
  font-size: 16px;
}

.tabs-categorys-list-two .menu-tabs li a {
  padding: 0 10px;
}

.tabs-categorys-list-two .menu-tabs li .active {
  color: #000;
  position: relative;
  font-size: 18px;
}

.tabs-categorys-list-two .menu-tabs li .active::after {
  position: absolute;
  content: "";
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  background: #80BB01;
  height: 2px;
  width: 50px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tabs-categorys-list-two .menu-tabs {
    padding: 0 52px 0 0;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  .tabs-categorys-list-two .menu-tabs li {
    padding-right: 10px;
  }
}

.product-row-6-active .slick-arrow, .product-one-active .slick-arrow, .product-row-3-active .slick-arrow, .latest-blog-active .slick-arrow {
  position: absolute;
  right: 8px;
  top: -35px;
  color: #242424;
  background: transparent;
  padding: 2px 0px;
  cursor: pointer;
  border: none;
  font-size: 20px;
}

.product-row-6-active .slick-arrow.slick-prev, .product-one-active .slick-arrow.slick-prev, .product-row-3-active .slick-arrow.slick-prev, .latest-blog-active .slick-arrow.slick-prev {
  right: 50px;
}

@media only screen and (max-width: 767px) {
  .product-row-6-active .slick-arrow, .product-one-active .slick-arrow, .product-row-3-active .slick-arrow, .latest-blog-active .slick-arrow {
    right: 5px;
  }
  .product-row-6-active .slick-arrow.slick-prev, .product-one-active .slick-arrow.slick-prev, .product-row-3-active .slick-arrow.slick-prev, .latest-blog-active .slick-arrow.slick-prev {
    right: 18px;
  }
}

.bg-image-04 {
  background: url(../images/bg/bg-4.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.product-wrapper-five .single-product-wrap {
  border: 1px solid transparent;
}

.product-box-wrapper {
  border: 1px solid #ddd;
  padding: 15px 8px 0 8px;
  background: #ffff;
  border-radius: 3px;
}

.border_none .single-product-wrap {
  border: 1px solid transparent;
}

.border_none .single-product-wrap:hover {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0);
}

.box-area-image {
  text-align: center;
}

.product-wrapper-five {
  border-left: 1px solid #ddd;
  padding: 0px 5px;
}

@media only screen and (max-width: 767px) {
  .product-wrapper-five {
    border-left: none;
  }
}

.col-imgbanner {
  flex: 0 0 27.1% !important;
  max-width: 27.1% !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-imgbanner {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

@media only screen and (max-width: 767px) {
  .col-imgbanner {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

@media only screen and (max-width: 479px) {
  .col-imgbanner {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.col-imgbanner + .col-single-product {
  flex: 0 0 31.4% !important;
  max-width: 31.4% !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-imgbanner + .col-single-product {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

@media only screen and (max-width: 767px) {
  .col-imgbanner + .col-single-product {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

@media only screen and (max-width: 479px) {
  .col-imgbanner + .col-single-product {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.col-imgbanner + .col-single-product + .col-group-products {
  flex: 0 0 40.6% !important;
  max-width: 40.6% !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-imgbanner + .col-single-product + .col-group-products {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 767px) {
  .col-imgbanner + .col-single-product + .col-group-products {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 479px) {
  .col-imgbanner + .col-single-product + .col-group-products {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.shop-product-list-wrap {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
}

.product-content-list {
  padding-right: 10px;
  border-right: 1px solid #ddd;
}

@media only screen and (max-width: 767px) {
  .product-content-list {
    border: none;
  }
}

.shop-product-wrap {
  margin-top: 10px;
}

.product-layout-list .price-box {
  color: #80bb01;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  width: 100%;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.product-layout-list .price-box .new-price {
  background: none;
  font-size: 1em;
  text-decoration: none;
  color: #80bb01;
  font-size: 16px;
  font-weight: 500;
}

.product-layout-list .price-box .old-price {
  color: #777;
  display: inline-block;
  font-size: .875em;
  font-weight: normal;
  margin-left: 4px;
  text-decoration: line-through;
  filter: alpha(opacity=100);
  opacity: 1;
}

.product-layout-list p {
  margin-bottom: 0;
}

.product-layout-list .product-name {
  color: #333333;
  font-size: 1em;
  margin-bottom: 10px;
  font-weight: normal;
}

.product-layout-list .product-name:hover {
  color: #80BB01;
}

.product-layout-list .product-rating {
  margin-bottom: 15px;
}

.product-layout-list .product-rating ul li {
  display: inline-block;
}

.product-layout-list .product-rating ul li a {
  padding-right: 0px;
  color: #E7C643;
  font-size: 16px;
}

.product-layout-list .product-rating ul li.bad-reting a {
  color: #CFCFCF;
}

.stock-cont li span.in-stock {
  color: #80BB01;
}

.block2 .product-button-action {
  margin-top: 10px;
}

.block2 .product-button-action .add-to-cart {
  background: #80bb01;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.025em;
  height: 40px;
  line-height: 40px;
  padding: 0 35px;
  text-shadow: none;
  text-transform: capitalize;
  text-align: center;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.block2 .product-button-action .add-to-cart:hover {
  background: #5d8801;
  border-color: #5d8801;
  color: #fff;
}

.block2 .actions {
  margin-top: 10px;
}

.block2 .actions li {
  margin-bottom: 5px;
}

.block2 .actions li a i {
  margin-right: 5px;
}

@media only screen and (max-width: 767px) {
  .block2 {
    margin-top: 20px;
  }
}

/*--
   07. Product Details Css
---------------------------*/
.pro-large-img a {
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #333;
  padding: 10px 20px;
  border-radius: 3px;
  color: #ffffff;
  transform: translate(-50%, -50%);
}

.pro-large-img a:hover {
  background: #80BB01;
}

.product-nav {
  padding: 0 30px;
}

.product-nav .pro-nav-thumb {
  padding: 0 5px;
  border: 1px solid transparent;
  opacity: 0.7;
}

.product-nav .pro-nav-thumb.slick-current {
  border: 1px solid #ddd;
  opacity: 1;
}

.product-nav .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-size: 18px;
  padding: 0;
  border: none;
  background: transparent;
}

.product-nav .slick-arrow.slick-next {
  right: 0;
  left: auto;
}

@media only screen and (max-width: 767px) {
  .product-details-view-content {
    margin-top: 30px;
  }
}

.product-details-view-content .product-rating li {
  padding-right: 5px;
  font-size: 16px;
}

.product-details-view-content .product-rating li a {
  color: #FECC0A;
}

.product-details-view-content .product-rating > a {
  margin-left: 10px;
}

.product-details-view-content .price-box {
  color: #80bb01;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 10px;
  width: 100%;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.product-details-view-content .price-box .new-price {
  background: none;
  text-decoration: none;
  color: #80bb01;
  font-weight: 500;
}

.product-details-view-content .price-box .old-price {
  color: #777;
  display: inline-block;
  font-weight: normal;
  margin-left: 4px;
  text-decoration: line-through;
  filter: alpha(opacity=100);
  opacity: 1;
}

.cart-plus-minus {
  margin-right: 15px;
}

.cart-plus-minus input {
  width: 40px;
  height: 40px;
  border: 1px solid #ddd;
  padding: 0 0 0 10px;
}

.single-add-to-cart {
  max-width: 290px;
}

.single-add-to-cart .add-to-cart {
  background: #80bb01;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.025em;
  height: 40px;
  line-height: 40px;
  padding: 0 35px;
  text-shadow: none;
  text-align: center;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  width: 100%;
}

.single-add-to-cart .add-to-cart:hover {
  background: #5d8801;
}

.single-add-actions {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.single-add-actions li {
  display: inline-block;
  margin-right: 15px;
  font-size: 16px;
}

.single-add-actions li a i {
  margin-right: 5px;
}

.share-product-socail-area {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.share-product-socail-area p {
  margin-bottom: 0;
}

.share-product-socail-area li {
  display: inline-block;
  margin-left: 10px;
}

.share-product-socail-area li a {
  height: 30px;
  width: 30px;
  line-height: 30px;
  background: #f1f1f1;
  text-align: center;
  border-radius: 3px;
  color: #333;
}

.share-product-socail-area li a:hover {
  background: #80BB01;
  color: #ffffff;
}

.product-description-area {
  border: 1px solid #ddd;
  margin-top: 60px;
  border-radius: 3px;
  padding: 0px 20px 20px 20px;
}

.product-details-tab {
  border-bottom: 1px solid #ddd;
}

.product-details-tab li {
  margin-right: 50px;
}

.product-details-tab li a {
  padding: 20px 0;
  font-size: 20px;
  font-weight: 500;
}

.product-details-tab li a.active {
  color: #80BB01;
}

@media only screen and (max-width: 767px) {
  .product-details-tab li a {
    padding: 10px 0;
  }
}

.select-opstion-box .color-select {
  min-width: 280px;
  border: 1px solid #ddd;
  padding: 5px 5px;
}

.single-product-gallery {
  float: left;
  padding: 5px 5px;
  width: 50%;
}

.single-product-gallery a {
  display: block;
  border: 1px solid #ddd;
}

.stock-cont li {
  margin-bottom: 2px;
}

.stock-cont li:last-child {
  margin-bottom: 0px;
}

.pro_review {
  display: flex;
}

@media only screen and (max-width: 479px) {
  .pro_review {
    flex-direction: column;
  }
  .pro_review .review_thumb {
    margin-bottom: 20px;
  }
}

.review_thumb {
  min-width: 62px;
}

.review_details {
  border: 1px solid #ddd;
  margin-left: 26px;
  padding: 10px 20px;
  position: relative;
  border-radius: 3px;
}

@media only screen and (max-width: 479px) {
  .review_details {
    margin-left: 0px;
  }
}

.review_details:before {
  background: #fff;
  border-bottom: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  content: '';
  display: block;
  height: 10px;
  left: -6px;
  position: absolute;
  top: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 10px;
}

.review_details .review_info h5 span {
  font-weight: 300;
}

.product-rating li {
  margin-right: 3px;
  font-size: 18px;
  color: #ffcc00;
}

.comments-reply-area .comment-input input, .comments-reply-area .comment-input textarea, .comments-reply-area .comment-form-comment input, .comments-reply-area .comment-form-comment textarea {
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px;
}

.comments-reply-area .comment-input textarea, .comments-reply-area .comment-form-comment textarea {
  height: 140px;
}

.comments-reply-area label {
  display: block;
  margin-bottom: 3px;
}

.comment-form-submit input {
  border: none;
  padding: 10px 30px;
  border-radius: 3px;
  background: #80BB01;
  color: #fff;
}

/*--
    08. Testimonial Css
--------------------------------*/
.testimonial-bg {
  background: url(../images/bg/testimonial-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.testimonial-wrap {
  padding-top: 50px;
}

.quote-container {
  margin: auto;
  background: #fff;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  padding: 60px 30px 25px;
  position: relative;
}

.quote-container .quote-image {
  display: inline-block;
  height: 90px;
  margin-right: 10px;
  overflow: hidden;
  vertical-align: middle;
  width: 90px;
  border: 7px solid #f6f5f1;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  position: absolute;
  left: 20px;
  top: -45px;
}

.quote-container .author ul li {
  font-size: 0.9286em;
  margin: 0 0 10px 0;
  display: inline-block;
  position: relative;
  text-align: left;
  vertical-align: middle;
  padding-right: 10px;
  margin-right: 10px;
}

.quote-container .author ul li::after {
  position: absolute;
  left: -15px;
  top: 0;
  content: "/";
  height: 100%;
  width: 100%;
}

.quote-container .author ul li:first-child {
  color: #80BB01;
  text-transform: uppercase;
  font-weight: 500;
}

.quote-container .author ul li:first-child::after {
  display: none;
}

.quote-container .author ul li:last-child {
  padding-right: 0px;
  margin-right: 0px;
}

.quote-container .testimonials-text::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  width: 23px;
  height: 17px;
  position: absolute;
  top: 35px;
  right: 5px;
  background: url(../images/icon/quite.png) center center no-repeat;
}

.testimonial-slider .slick-dots {
  text-align: center;
  margin-top: 30px;
  bottom: 0px;
  left: 0;
  right: 0;
}

.testimonial-slider .slick-dots li {
  display: inline-block;
  margin: 0 4px;
}

.testimonial-slider .slick-dots li button {
  background: #f1f1ff;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 0;
  line-height: 0;
  display: block;
  width: 8px;
  height: 8px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  border-radius: 50%;
}

.testimonial-slider .slick-dots li.slick-active button {
  background: #80BB01;
  width: 10px;
  height: 10px;
}

.testimonial-wrap-two .quote-container {
  padding: 0;
}

.testimonial-wrap-two .quote-container .quote-image {
  margin-right: 0px;
  position: inherit;
  left: 0px;
  top: 0px;
}

.testimonial-wrap-two .quote-container .author {
  margin-top: 25px;
}

.testimonial-wrap-two .quote-container .author h6 {
  font-weight: 500;
  text-transform: uppercase;
  color: #80BB01;
}

.testimonial-wrap-two .quote-container .testimonials-text {
  position: relative;
  margin-top: 70px;
}

.testimonial-wrap-two .quote-container .testimonials-text::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  width: 23px;
  height: 17px;
  position: absolute;
  top: -60px;
  right: 0;
  left: 0;
  margin: 0 auto;
  background: url(../images/icon/quite.png) center center no-repeat;
}

/*--
    09. Banner Area Css
---------------------------------*/
.single-banner {
  overflow: hidden;
}

.single-banner a {
  display: block;
}

.single-banner a img {
  transform: scale(1);
  transition: all .3s ease-in-out;
}

.single-banner a:hover img {
  transform: scale(1.02);
}

/*--
    10. Blog Area Css
----------------------------------*/
.single-latest-blog .latest-blog-image, .single-blog-wrap .latest-blog-image {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}

.single-latest-blog .latest-blog-image a, .single-blog-wrap .latest-blog-image a {
  overflow: hidden;
  display: block;
}

.single-latest-blog .latest-blog-image a img, .single-blog-wrap .latest-blog-image a img {
  transform: scale(1);
}

.single-latest-blog .latest-blog-image:hover a img, .single-blog-wrap .latest-blog-image:hover a img {
  transform: scale(1.05);
}

.single-latest-blog .latest-blog-content, .single-blog-wrap .latest-blog-content {
  margin-top: 20px;
}

.single-latest-blog .latest-blog-content .post-meta li, .single-blog-wrap .latest-blog-content .post-meta li {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.single-latest-blog .latest-blog-content .post-meta li::after, .single-blog-wrap .latest-blog-content .post-meta li::after {
  position: absolute;
  left: 0;
  content: "/";
  top: 0;
}

.single-latest-blog .latest-blog-content .post-meta li:first-child, .single-blog-wrap .latest-blog-content .post-meta li:first-child {
  padding-left: 0px;
  margin-left: 0px;
}

.single-latest-blog .latest-blog-content .post-meta li:first-child::after, .single-blog-wrap .latest-blog-content .post-meta li:first-child::after {
  display: none;
}

.single-latest-blog .latest-blog-content .post-meta li a, .single-blog-wrap .latest-blog-content .post-meta li a {
  color: #80BB01;
}

.single-latest-blog .latest-blog-content .post-meta li a:hover, .single-blog-wrap .latest-blog-content .post-meta li a:hover {
  text-decoration: underline;
}

.single-latest-blog .latest-blog-content .blog-read-more a, .single-blog-wrap .latest-blog-content .blog-read-more a {
  margin-top: 5px;
  background: #80BB01;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.025em;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  padding: 0 35px;
  text-align: center;
  transition: ease-in-out 0.3s;
}

.single-latest-blog .latest-blog-content .blog-read-more a:hover, .single-blog-wrap .latest-blog-content .blog-read-more a:hover {
  background: #5D8801;
}

.post-wrapper .latest-blog-list {
  padding: 20px 0;
  padding-top: 20px;
  border-bottom: 1px solid #ebebeb;
}

.post-wrapper .latest-blog-list .post-meta li {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.post-wrapper .latest-blog-list .post-meta li::after {
  position: absolute;
  left: 0;
  content: "/";
  top: 0;
}

.post-wrapper .latest-blog-list .post-meta li:first-child {
  padding-left: 0px;
  margin-left: 0px;
}

.post-wrapper .latest-blog-list .post-meta li:first-child::after {
  display: none;
}

.post-wrapper .latest-blog-list .post-meta li a {
  color: #80BB01;
}

.post-wrapper .latest-blog-list .post-meta li a:hover {
  text-decoration: underline;
}

.post-wrapper .latest-blog-list:last-child {
  padding-bottom: 0px;
  border-bottom: none;
}

.box-wrap {
  padding: 0 15px 30px 15px;
}

.single-agence {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}

.single-agence a {
  overflow: hidden;
}

.single-agence a img {
  transform: scale(1);
}

.single-agence:hover a img {
  transform: scale(1.06);
}

.agence-content {
  display: flex;
  align-items: center;
  color: #777;
  padding: 12px 0;
  padding-top: 15px;
  border-bottom: 1px solid #ebebeb;
}

.agence-content:last-child {
  padding-bottom: 0px;
  border-bottom: none;
}

.agence-content p {
  margin-bottom: 0;
}

.agence-content p i {
  color: #80BB01;
  font-size: 20px;
}

.agence-content p.dec {
  padding-left: 10px;
}

.agence-content p strong {
  font-weight: 400;
  color: #333333;
}

.single-blog-wrap {
  overflow: hidden;
}

.single-blog-wrap .col-5-custom {
  float: left;
  padding-right: 25px;
  max-width: 45%;
}

@media only screen and (max-width: 767px) {
  .single-blog-wrap .col-5-custom {
    max-width: 100%;
  }
}

.single-blog-wrap .col-7-custom {
  float: left;
  max-width: 55%;
}

.single-blog-wrap .col-7-custom .latest-blog-content {
  margin-top: 0;
}

@media only screen and (max-width: 767px) {
  .single-blog-wrap .col-7-custom {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .single-blog-wrap .latest-blog-content {
    margin-top: 20px;
  }
}

.single-blog-wrap .latest-blog-content .post-meta {
  color: #777;
  padding-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 15px;
}

.post-category-tag {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.post-category-tag li {
  display: inline-block;
}

.post-category-tag li a {
  background: #fff;
  border-radius: 5px;
  display: block;
  padding: 4px 10px;
}

.meta-sharing {
  border-top: 1px solid #ddd;
  margin-top: 40px;
}

.blockquote-box {
  background: #f6f6f6;
  border: 1px solid #ebebeb;
  border-left-color: #ebebeb;
  border-left-style: solid;
  border-left-width: 1px;
  border-left: 5px solid #80bb01;
  font-size: 1em;
  font-style: italic;
  margin: 40px 0 40px 40px;
  padding: 30px 45px;
}

/*--
    11. Footer Css
-------------------------*/
.footer-top {
  background: #2f2f2f;
}

.widget-footer .title-widget {
  margin-bottom: 30px;
  color: #ffffff;
  text-transform: uppercase;
}

.widget-footer p {
  color: #ffffff;
}

.contact-call-wrap, .contact-call-wrap-top {
  display: flex;
  align-items: center;
}

.contact-call-wrap .footer-call, .contact-call-wrap-top .footer-call {
  margin-left: 15px;
}

.contact-call-wrap .footer-call p, .contact-call-wrap-top .footer-call p {
  color: #ffffff;
  font-size: 1em;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 10px;
}

.contact-call-wrap .footer-call h6, .contact-call-wrap-top .footer-call h6 {
  color: #80bb01;
  font-size: 1.2857em;
  line-height: 1;
}

.contact-call-wrap-top .footer-call {
  margin-left: 15px;
}

.contact-call-wrap-top .footer-call p {
  color: #333;
}

.footer-addres {
  margin-top: 10px;
}

.footer-addres p {
  color: #fff;
  margin-bottom: 0;
}

.social-icons {
  margin-top: 20px;
}

.social-icons li {
  display: inline-block;
  margin: 0 10px 5px 0;
  position: relative;
  vertical-align: middle;
}

.social-icons li a {
  color: #fff;
  display: block;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.newletter-wrap .newsletter-title h3 {
  color: #ffffff;
  margin-bottom: 0;
}

.newletter-wrap .newsletter-title h3 span {
  color: #80BB01;
}

@media only screen and (max-width: 767px) {
  .newletter-wrap .newsletter-title h3 {
    line-height: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .newletter-wrap .newsletter-title.mb-30 {
    margin-bottom: 15px;
  }
}

.newletter-wrap p {
  color: #ffffff;
  margin-bottom: 0;
}

.newletter-area {
  background: #2f2f2f;
}

.newletter-wrap {
  border-top: 1px solid #ccc;
  padding-top: 30px;
}

.newsletter-footer {
  position: relative;
}

.newsletter-footer input {
  background: #fff;
  border: none;
  color: #888888;
  display: block;
  font-size: 0.929em;
  font-style: italic;
  height: 45px;
  line-height: 45px;
  width: 100%;
  padding: 0 140px 0 20px;
  text-align: left;
  white-space: nowrap;
  border-radius: 3px;
}

.newsletter-footer .subscribe-button {
  width: 100%;
}

.newsletter-footer .subscribe-btn {
  position: absolute;
  top: 0;
  right: 0;
  background: #80BB01;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 0.8571em;
  height: 45px;
  line-height: 45px;
  margin: 0;
  font-weight: 500;
  padding: 0 30px;
  text-align: center;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.newsletter-footer .subscribe-btn:hover {
  background: #5D8801;
}

.footer-list li {
  margin-bottom: 8px;
  color: #fff;
}

.footer-list li:last-child {
  margin-bottom: 0;
}

.footer-bottom {
  background: #0E7346;
  padding: 40px 0;
  color: #fff;
}

.footer-bottom .copy-right-image {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .footer-bottom {
    text-align: center;
  }
  .footer-bottom .copy-left-text {
    margin-bottom: 20px;
  }
  .footer-bottom .copy-right-image {
    text-align: center;
  }
}

/*--
    12. Breadcrumb Style
------------------------------------------*/
.breadcrumb-area {
  background: #F9F9F9;
  padding: 17px 0;
  border: 1px solid #ebebeb;
}

.breadcrumb-list .breadcrumb-item {
  font-size: 14px;
  text-transform: capitalize;
  line-height: 1;
  display: inline-block;
}

.breadcrumb-list .breadcrumb-item.active {
  color: #80BB01;
}

.breadcrumb-list .breadcrumb-item a:hover {
  color: #80BB01;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "/";
  display: inline-block;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

/*--
    13. Widget Css
---------------------*/
.shop-box-area, .blog-sidebar-widget-area {
  border: 1px solid #ebebeb;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 20px;
  background: #f9f9f9;
}

.shop-sidebar h4 {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 25px;
  padding-bottom: 15px;
  position: relative;
}

.shop-sidebar h4::before {
  position: absolute;
  content: "";
  background: #ebebeb;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: -1px;
}

.widget-title {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 25px;
  padding-bottom: 15px;
  position: relative;
}

.widget-title::before {
  position: absolute;
  content: "";
  background: #ebebeb;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: -1px;
}

.category-sub-menu ul li.has-sub > a {
  color: #363f4d;
  cursor: pointer;
  display: block;
  font-size: 14px;
  line-height: 14px;
  padding: 10px 0 10px 15px;
  position: relative;
}

.category-sub-menu ul li.has-sub > a:after {
  color: #333333;
  content: "\e930";
  font-family: plaza-icon;
  font-size: 14px;
  position: absolute;
  right: 5px;
}

.category-sub-menu ul li.has-sub > a::before {
  color: #333333;
  content: "+";
  font-family: plaza-icon;
  position: absolute;
  left: 0px;
}

.category-sub-menu ul li.has-sub > a:hover {
  color: #80BB01;
}

.category-sub-menu ul li.has-sub.open {
  display: block;
  width: auto;
}

.category-sub-menu ul li.has-sub.open > a:after {
  transform: rotate(-90deg);
}

.category-sub-menu ul li.has-sub li a {
  border: 0 none;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  display: block;
  font-size: 14px;
  line-height: 14pxpx;
  margin: 0 0px 10px 0;
  padding: 0 28px 5px;
  position: relative;
  color: #333;
}

.category-sub-menu ul li.has-sub li a:hover {
  color: #80BB01;
}

.category-sub-menu ul li.has-sub li a::before {
  color: #333333;
  content: "+";
  font-family: plaza-icon;
  position: absolute;
  left: 10px;
}

.category-sub-menu ul li.has-sub li:last-child a {
  border: none;
  margin-bottom: 10px;
}

.category-sub-menu ul li ul {
  display: none;
  margin-top: 10px;
}

.ui-widget-content {
  background: #DBDBDB none repeat scroll 0 0;
  border: medium none;
  border-radius: 5px;
  height: 4px;
  margin: 35px 0 25px;
}

.ui-widget-content .ui-state-default {
  background: #80BB01;
  border: 2px solid #ffffff;
  border-radius: 25px;
  box-shadow: none;
  color: #ffffff;
  cursor: pointer;
  font-weight: normal;
  height: 15px;
  width: 15px;
}

.ui-widget-content .ui-state-default:hover {
  background: #80BB01;
}

.ui-widget-content .ui-slider-range {
  background: #80BB01;
}

.filter-price-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  font-size: 13px;
  margin-bottom: 10px;
}

.filter-price-wapper .add-to-cart-button {
  background: #333;
  display: inline-block;
  font-weight: 500;
  padding: 4px 13px;
  right: 0;
  color: #ffffff;
  border-radius: 5px;
  margin-bottom: 10px;
}

.filter-price-wapper .add-to-cart-button:hover {
  background: #000000;
}

.filter-price-cont {
  font-size: 14px;
}

.filter-price-cont .input-type input {
  border: medium none;
  padding: 0 8px 0 0;
  text-align: center;
  width: 40px;
  background: transparent;
  font-weight: 500;
}

.filter-price-cont .input-type:last-child {
  padding-left: 8px;
}

.category-widget-list li a {
  margin-bottom: 10px;
  position: relative;
  padding: 0 0 0 15px;
  line-height: auto;
}

.category-widget-list li a::before {
  color: #333333;
  content: "+";
  font-family: plaza-icon;
  position: absolute;
  left: 0px;
  top: 45%;
  transform: translateY(-50%);
}

.sidebar-tag li {
  display: inline-block;
}

.sidebar-tag li > a {
  border: 1px solid #ddd;
  color: #333;
  font-size: 13px;
  margin: 10px 10px 0  0;
  padding: 4px 15px;
  border-radius: 5px;
  text-transform: capitalize;
}

.sidebar-tag li > a:hover {
  background: #80BB01;
  color: #ffffff;
  border: 1px solid #80BB01;
}

.shop-top-bar {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  align-items: center;
  border-radius: 3px;
}

.shop-item-filter-list li a::before {
  content: '';
  display: block;
  height: 20px;
  width: 25px;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.shop-item-filter-list li a.grid-view::before {
  background: url(../images/icon/gridview.png) no-repeat center center;
}

.shop-item-filter-list li a.grid-view.active::before {
  background: url(../images/icon/gridview-hover.png) no-repeat center center;
}

.shop-item-filter-list li a.list-view::before {
  background: url(../images/icon/listview.png) no-repeat center center;
}

.shop-item-filter-list li a.active::before {
  background: url(../images/icon/listview-hover.png) no-repeat center center;
}

.shop-item-filter-list li .active {
  color: #80BB01;
}

.shop-banner img {
  width: 100%;
}

.product-short {
  display: flex;
  font-size: 14px;
  align-items: center;
}

.product-short p {
  margin: 0 15px 0 0;
}

@media only screen and (max-width: 479px) {
  .product-short p {
    display: none;
  }
}

.product-short .nice-select {
  border: 1px solid #ddd;
  padding: 0px 15px;
  height: 32px;
  width: 170px;
  line-height: 32px;
  border-radius: 3px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-sidebar-wrap {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-sidebar-wrap {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 479px) {
  .shop-sidebar-wrap {
    margin-top: 60px;
  }
}

.search-widget .form-input {
  position: relative;
}

.search-widget .form-input input {
  background: #fff;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 50px 0 15px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ebebeb;
}

.search-widget .form-input .button-search {
  background: transparent;
  border: none;
  color: #777;
  cursor: pointer;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 40px;
}

.search-widget .form-input .button-search:hover {
  color: #80BB01;
}

.single-widget-post {
  overflow: hidden;
  margin-bottom: 20px;
}

.single-widget-post .post-thumb {
  float: left;
  max-width: 30%;
}

.single-widget-post .post-info {
  float: left;
  padding-left: 20px;
  max-width: 70%;
}

.single-widget-post .post-info .post-date {
  font-size: 13px;
}

/*--
    14. Deals Offer Css
--------------------------------*/
.dealis-offer-bg {
  background: url(../images/bg/dealis-offer-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.deals-offer-title {
  margin-top: 80px;
}

@media only screen and (max-width: 767px) {
  .deals-offer-title {
    margin-top: 0px;
  }
}

.product-active-lg-4 .slick-arrow {
  position: absolute;
  left: -140px;
  top: 65%;
  transform: translateY(-65%);
  height: 35px;
  line-height: 35px;
  color: #242424;
  background: #fff;
  border: 1px solid #ebebeb;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 0 20px;
  cursor: pointer;
}

.product-active-lg-4 .slick-arrow.slick-arrow {
  margin-left: -50px;
}

.product-active-lg-4 .slick-arrow.slick-next {
  margin-left: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-active-lg-4 .slick-arrow {
    left: -220px;
    transform: translateY(0%);
  }
  .product-active-lg-4 .slick-arrow.slick-arrow {
    margin-left: -0px;
  }
  .product-active-lg-4 .slick-arrow.slick-next {
    margin-left: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-active-lg-4 .slick-arrow {
    left: -220px;
    transform: translateY(0%);
  }
  .product-active-lg-4 .slick-arrow.slick-arrow {
    margin-left: -0px;
  }
  .product-active-lg-4 .slick-arrow.slick-next {
    margin-left: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .product-active-lg-4 .slick-arrow {
    left: 20px;
    top: -30px;
    transform: translateY(0%);
  }
  .product-active-lg-4 .slick-arrow.slick-arrow {
    margin-left: -0px;
  }
  .product-active-lg-4 .slick-arrow.slick-next {
    margin-left: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .product-active-lg-4 {
    margin-top: 40px;
  }
}

/*--
    15. Brand Css
------------------------*/
.brand-bg {
  background: url(../images/bg/brand-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.brand-group .brand-item {
  padding: 10px 0;
  text-align: center;
  border-bottom: 1px solid #ebebeb;
}

.brand-group .brand-item img {
  transform: scale(1);
}

.brand-group .brand-item:hover img {
  transform: scale(1.1);
}

.brand-group .brand-item:last-child {
  border: none;
}

.our-brand-active .brand-group {
  border-right: 1px solid #ddd;
}

@media only screen and (max-width: 479px) {
  .our-brand-active .brand-group {
    border-right: none;
  }
}

.our-brand-active .brand-group.last-elem {
  border-right: none;
}

.brand-box {
  padding: 30px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 3px;
}

/*--
    16. About US css
---------------------------*/
.about-welcome-text h2 {
  max-width: 800px;
  margin: auto;
  font-weight: 400;
  line-height: 50px;
}

.about-welcome-text .welcome-dec {
  max-width: 900px;
  margin: 30px auto;
}

.service-bg {
  background: url(../images/bg/about-us-policy-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.panel-heading .panel-title {
  margin-bottom: 0;
}

.panel-heading .panel-title a {
  background: #f5f5f5;
  display: block;
  padding: 14px 20px;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  color: inherit;
  position: relative;
  -webkit-transition: color .2s ease-in-out;
  -o-transition: color .2s ease-in-out;
  transition: color .2s ease-in-out;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.panel-heading .panel-title a::before {
  position: absolute;
  content: "\f0d7";
  font-family: 'FontAwesome';
  color: #333;
  font-size: 20px;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 25px;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.4s ease-in-out;
  border-radius: 100%;
  z-index: 4;
  margin-top: 0px;
}

.panel-heading .panel-title a[aria-expanded="true"]::before {
  background: #fff;
  color: #80BB01;
}

.faq-style-wrap .panel-title a[aria-expanded="true"] {
  border-radius: 3px 3px 0 0;
  color: #ffffff;
  background: #80BB01;
}

.panel.panel-default {
  margin-bottom: 15px;
}

.panel-body {
  padding: 15px;
  border: 1px solid #ddd;
}

/*--    
    17. Contact Us Css
-----------------------------*/
.contact-us-box .single-input {
  margin-bottom: 20px;
}

.contact-us-box .single-input label {
  display: block;
  margin-bottom: 3px;
}

.contact-us-box .single-input input, .contact-us-box .single-input textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
}

.contact-us-box .single-input textarea {
  height: 150px;
}

.contact-us-box .single-input .submit-button {
  margin-top: 20px;
  background: #80bb01;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.025em;
  height: 40px;
  line-height: 40px;
  padding: 0 35px;
  text-transform: capitalize;
  text-align: center;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.contact-info-text ul li {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 30px;
  padding-bottom: 40px;
}

.contact-info-text ul li:last-child {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0;
}

.contact-info-text ul li .contact-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contact-info-text ul li .contact-title i {
  font-size: 35px;
  margin-right: 10px;
}

.contact-info-text ul li .contact-title h4 {
  margin-bottom: 0;
}

/*--
    18. Cart Page Css
-----------------------------*/
.table-content table {
  background: #ffffff none repeat scroll 0 0;
  border-color: #ebebeb;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
  text-align: center;
}

.table-content table td {
  border-top: medium none;
  font-size: 16px;
  padding: 20px 10px;
  vertical-align: middle;
  min-width: 140px;
}

.table-content th,
.table-content td {
  border-bottom: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
}

.plantmore-product-quantity > input {
  text-align: center;
  width: 48px;
}

.cart-table .coupon-all {
  margin-top: 50px;
}

.cart-table .coupon-all .coupon h3 {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: -8px;
}

.cart-table .coupon-all .coupon input {
  border: 1px solid #999999;
  font-size: 14px;
  margin-right: 10px;
  padding: 6px 10px;
  width: 130px;
}

.cart-table .coupon-all .coupon input.button {
  background: #000000;
  border: 0 none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  height: 36px;
  letter-spacing: 1px;
  line-height: 36px;
  padding: 0 14px;
  text-transform: uppercase;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: inherit;
}

.cart-table .coupon-all .coupon input.button:hover {
  background: #80BB01;
}

@media only screen and (max-width: 767px) {
  .cart-table .coupon-all .coupon input.button {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .cart-table .coupon-all .coupon input {
    width: 125px;
  }
}

.cart-table .coupon-all .coupon2 {
  margin-bottom: 40px;
}

.cart-table .coupon-all .coupon2 input {
  margin-right: 8px;
}

.cart-table .coupon-all .coupon2 input.submit {
  padding: 8px 15px;
  background: #000000;
  color: #ffffff;
  border: 1px solid #000000;
}

.cart-table .coupon-all .coupon2 input.submit:hover {
  background: #80BB01;
  border: 1px solid #80BB01;
}

.cart-table .coupon-all .coupon2 .continue-btn {
  padding: 9px 15px;
  background: #000000;
  color: #fff;
}

.cart-table .coupon-all .coupon2 .continue-btn:hover {
  background: #80BB01;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .cart-table .coupon-all .coupon2 .continue-btn {
    margin-top: 20px;
  }
}

.cart-page-total {
  margin-top: 40px;
}

.cart-page-total h2 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 16px;
  text-transform: capitalize;
}

.cart-page-total ul {
  border: 1px solid #dddddd;
}

.cart-page-total ul li {
  border-bottom: 1px solid #ebebeb;
  color: #252525;
  font-family: "Montserrat",sans-serif;
  font-size: 15px;
  font-weight: 700;
  list-style: outside none none;
  padding: 10px 30px;
}

.cart-page-total ul li:last-child {
  border: none;
}

.cart-page-total ul li span {
  float: right;
}

.cart-page-total .proceed-checkout-btn {
  background: #000000;
  color: #ffffff;
  display: inline-block;
  margin-top: 30px;
  padding: 8px 16px;
  text-transform: capitalize;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.cart-page-total .proceed-checkout-btn:hover {
  background: #80BB01;
}

.plantmore-product-add-cart > a {
  background: #000000;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 30px;
  text-transform: capitalize;
  -webkit-transform: 0.4s;
  -ms-transform: 0.4s;
  transform: 0.4s;
}

.plantmore-product-add-cart > a:hover {
  background: #80BB01;
}

.in-stock {
  color: #30b878;
}

.out-stock {
  color: #A80135;
}

/*-- 
   19. Checkout Page Css
------------------------------*/
.coupon-accordion h3 {
  background: #f6f6f6;
  border-top: 3px solid #80BB01;
  color: #515151;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 30px;
  padding: 1em 2em 1em 3.5em;
  position: relative;
  text-transform: capitalize;
}

.coupon-accordion h3 .coupon {
  color: #80BB01;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.coupon-accordion h3 .coupon:hover {
  color: #000000;
}

.coupon-content {
  border: 1px solid #dddddd;
  margin-bottom: 30px;
  padding: 20px;
  display: none;
}

.coupon-info p.form-row-first {
  float: left;
  width: 48%;
}

@media only screen and (max-width: 479px) {
  .coupon-info p.form-row-first {
    width: 100%;
  }
}

.coupon-info p.form-row-last {
  float: right;
  width: 48%;
}

@media only screen and (max-width: 479px) {
  .coupon-info p.form-row-last {
    width: 100%;
  }
}

.coupon-info .remember {
  margin-left: 10px;
}

.coupon-info .remember span {
  margin-left: 5px;
}

.coupon-input label {
  display: block;
  font-size: 14px;
}

.coupon-input input {
  border: 1px solid #999999;
  color: #000000;
  padding: 5px 10px;
  width: 100%;
  font-size: 14px;
}

.coupon-input span.required {
  color: red;
}

.checkout-coupon input {
  border: 1px solid #999999;
  color: #555;
  padding: 5px 10px;
  width: auto;
}

.checkout-coupon input:focus {
  outline: none;
}

.checkout-coupon .button-apply-coupon {
  margin: -5px 0 0 10px;
  padding: 7.2px 11px;
}

@media only screen and (max-width: 479px) {
  .checkout-coupon .button-apply-coupon {
    margin: 10px 0 0 0px;
  }
}

.shoping-checkboxt-title {
  border-bottom: 1px solid #dddddd;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.single-form-row {
  margin-bottom: 20px !important;
}

.single-form-row label {
  font-size: 14px;
  margin-bottom: 2px;
}

.single-form-row label span.required {
  color: red;
}

.single-form-row input {
  border: 1px solid #999999;
  color: #666;
  font-size: 14px;
  padding: 5px 12px;
  width: 100%;
}

.single-form-row input::focus {
  outline: none;
}

.single-form-row textarea {
  border: 1px solid #999999;
  color: #555555;
  padding: 12px;
  width: 100%;
  font-size: 14px;
}

.single-form-row.m-0 {
  margin: 0 !important;
}

.checkout-box-wrap p {
  font-size: 14px;
}

.checkout-box-wrap .ship-box-info {
  display: none;
}

.account-create {
  display: none;
}

.account-create .creat-pass > span {
  color: red;
}

.nice-select select {
  height: 35px;
  width: 100%;
  font-size: 14px;
  padding: 0 10px;
  color: #555;
  border: 1px solid #999;
}

@media only screen and (max-width: 767px) {
  .your-order-wrapper {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 479px) {
  .your-order-wrapper {
    margin-top: 60px;
  }
}

.your-order-wrap {
  background: #f6f6f6;
}

.your-order-table {
  padding: 20px 30px;
}

.your-order-table table {
  width: 100%;
}

.your-order-table table th, .your-order-table table td {
  border-bottom: 1px solid #d8d8d8;
  border-right: medium none;
  font-size: 14px;
  padding: 15px 0;
  text-align: center;
}

.your-order-table table th {
  border-top: medium none;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px;
}

.your-order-table table .shipping > th {
  vertical-align: top;
}

.payment-method {
  padding: 20px 30px;
}

.payment-accordion h3 a {
  color: #333333;
  font-size: 15px;
  font-weight: 500;
  padding-left: 31px;
  position: relative;
  text-decoration: none;
  text-transform: capitalize;
}

.payment-accordion h3 a::before, .payment-accordion h3 a::after {
  content: "\f216";
  display: inline-block;
  font-family: ionicons;
  font-size: 19px;
  left: 0;
  position: absolute;
  top: 0px;
}

.payment-accordion h3 a img {
  height: 60px;
  display: block;
}

.payment-accordion h3.open a::after {
  content: "\f207";
}

.payment-accordion p {
  font-size: 14px;
  padding-left: 20px;
}

.order-button-payment {
  margin-top: 30px;
}

.order-button-payment input {
  background: #80BB01;
  border: medium none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  padding: 12px;
  text-transform: uppercase;
  width: 100%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.order-button-payment input:hover {
  background: #000000;
  color: #ffffff;
}

/*--    
    20. Error 404 Page Css
---------------------------*/
.search-error-wrapper h1 {
  color: #80BB01;
  font-size: 120px;
  line-height: 86px;
  margin-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .search-error-wrapper h1 {
    font-size: 70px;
    line-height: 50px;
    margin-bottom: 40px;
  }
}

.error-form-input {
  position: relative;
  width: 275px;
  margin: 30px auto;
}

.error-form-input input {
  border: 1px solid #dddddd;
  font-size: 15px;
  padding: 5px 45px 6px 10px;
  position: relative;
  width: 275px;
  border-radius: 35px;
}

.error-form-input .error-s-button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 7px 15px;
  position: absolute;
  right: 0;
  top: 0;
}

.search-error-wrapper .home-bacck-button {
  background: #80BB01;
  border-radius: 35px;
  box-shadow: none;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  text-transform: uppercase;
  -webkit-transition: 0.4;
  transition: 0.4;
}

/*--
    21. Compare Page Css
-----------------------------------------*/
.compare-table .table tbody tr:first-child {
  border-top: 1px solid #eeeeee;
}

.compare-table .table tbody tr td {
  text-align: center;
  border: none;
  padding: 25px 30px;
  vertical-align: middle;
  border-bottom: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}

.compare-table .table tbody tr td:last-child {
  border-right: 1px solid #eeeeee;
}

.compare-table .table tbody tr td.first-column {
  min-width: 180px;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  margin: 0;
  line-height: 1;
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 310px;
  vertical-align: bottom;
  padding-top: 70px;
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 40px;
}

.compare-table .table tbody tr td.product-image-title .image img {
  max-width: 100%;
}

.compare-table .table tbody tr td.product-image-title .category {
  clear: both;
  color: #454545;
  float: left;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 15px;
  margin-bottom: 7px;
  margin-top: 15px;
  text-transform: capitalize;
}

.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  line-height: 20px;
  margin-bottom: 10px;
}

.compare-table .table tbody tr td.pro-desc p {
  text-align: left;
  margin: 0;
}

.compare-table .table tbody tr td.pro-price {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.compare-table .table tbody tr td.pro-color {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.compare-table .table tbody tr td.pro-stock {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart {
  position: relative;
  background-color: #f6f6f6;
  color: #000000;
  border-radius: 50px;
  display: inline-block;
  width: 170px;
  padding: 10px 6px;
  transition: all 0.7s;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart span {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 21px;
  overflow: hidden;
  display: block;
  text-align: center;
}

.compare-table .table tbody tr td.pro-remove button {
  border: none;
  background-color: transparent;
  padding: 0;
}

.compare-table .table tbody tr td.pro-remove button i {
  font-size: 20px;
}

.compare-table .table tbody tr td.pro-ratting i {
  color: #000000;
  font-size: 14px;
}

/*--
    22. My Account Page Css
--------------------------*/
.dashboard-upper-info [class*="col-"]:not(:last-child) .d-single-info {
  border-right: 1px solid #ebebeb;
  padding: 30px 20px;
}

.dashboard-upper-info [class*="col-"]:not(:last-child) .d-single-info p {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dashboard-upper-info [class*="col-"]:not(:last-child) .d-single-info {
    border-right: medium none;
    padding: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard-upper-info [class*="col-"]:not(:last-child) .d-single-info {
    border-right: medium none;
    padding: 0 0 20px;
  }
}

.dashboard-upper-info {
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
  margin-bottom: 60px;
}

.dashboard-upper-info a.view-cart {
  background: #80BB01;
  color: #ffffff;
  font-size: 14px;
  padding: 5px 12px;
  text-transform: capitalize;
}

.dashboard-upper-info a.view-cart:hover {
  background: #000000;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dashboard-upper-info {
    border-bottom: medium none;
    border-top: medium none;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard-upper-info {
    border-bottom: medium none;
    border-top: medium none;
  }
}

.user-name span {
  color: #303030;
  font-size: 16px;
  font-weight: 600;
}

.dashboard-list li a {
  border-bottom: 1px solid #ebebeb;
  color: #222222;
  display: block;
  font-size: 14px;
  padding: 12px 0;
  text-transform: capitalize;
}

.dashboard-list li:first-child {
  border-top: 1px solid #ebebeb;
}

.dashboard-content {
  border: 1px solid #ebebeb;
  padding: 30px;
}

.dashboard-content h3 {
  border-bottom: 1px solid #ebebeb;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 25px;
  padding-bottom: 15px;
  text-transform: capitalize;
}

.dashboard-content .table thead {
  background: #f6f6f6 none repeat scroll 0 0;
  border-bottom: 1px solid #ebebeb;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dashboard-content .table td {
    min-width: 260px;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard-content .table td {
    min-width: 260px;
  }
}

.dashboard-content .view {
  background: #80BB01;
  color: #ffffff;
  font-size: 14px;
  padding: 5px 12px;
  text-transform: capitalize;
}

.dashboard-content .view:hover {
  background: #000000;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dashboard-content {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard-content {
    margin-top: 60px;
  }
}

.billing-address {
  margin-bottom: 10px;
}

.biller-name {
  margin-bottom: 0;
  margin-top: 10px;
}

.account-login-form .custom-checkbox {
  display: flex;
}

.account-login-form input[type="checkbox"] {
  height: 20px;
  margin: 0 10px 0 0;
  position: relative;
  top: 3px;
  width: auto;
}

.account-login-form .example {
  margin-bottom: 15px;
}

.account-login-form .default-btn {
  margin-top: 15px;
  padding: 6px 28px;
}

.account-input-box label {
  display: block;
  font-size: 15px;
  margin-bottom: 3px;
}

.account-input-box input {
  width: 100%;
  margin-bottom: 12px;
  border: 1px solid #ddd;
  padding: 4px 10px;
  font-size: 15px;
}

/*--
    23. Login And Register Css
----------------------------------*/
.login-register-tab-list {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.login-register-tab-list a {
  position: relative;
}

.login-register-tab-list a::before {
  background-color: #454545;
  bottom: 5px;
  content: "";
  height: 18px;
  margin: 0 auto;
  position: absolute;
  right: -2px;
  top: 3px;
  transition: all 0.4s ease 0s;
  width: 1px;
}

.login-register-tab-list a:last-child:before {
  display: none;
}

.login-register-tab-list a.active h4,
.login-register-tab-list a:hover h4 {
  color: #80BB01;
}

.login-register-tab-list a h4 {
  font-size: 25px;
  font-weight: 700;
  margin: 0 20px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}

.login-input-box input {
  border: 1px solid #999999;
  color: #666666;
  margin-bottom: 20px;
  padding: 8px 12px;
  width: 100%;
  font-size: 14px;
}

.login-toggle-btn a {
  float: right;
  color: red;
  font-size: 14px;
}

@media only screen and (max-width: 479px) {
  .login-toggle-btn a {
    float: inherit;
  }
}

.login-toggle-btn input {
  margin-right: 5px;
}

.login-form-container {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  padding: 60px;
  text-align: left;
}

@media only screen and (max-width: 479px) {
  .login-form-container {
    padding: 30px;
  }
}

.login-form-container .login-btn,
.login-form-container .register-btn {
  margin-top: 20px;
  padding: 8px 30px;
}
