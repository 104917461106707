/*--
    16. About US css
---------------------------*/
.about-welcome-text {
    & h2 {
        max-width: 800px;
        margin: auto;
        font-weight: 400;
        line-height: 50px;
    }
    & .welcome-dec {
        max-width: 900px;
        margin: 30px auto;
    }
}
.service-bg {
    background: url(../images/bg/about-us-policy-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.panel-heading {
    & .panel-title {
        margin-bottom: 0;
        & a {
            background: #f5f5f5;
            display: block;
            padding: 14px 20px;
            border-radius: 3px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            text-decoration: none;
            color: inherit;
            position: relative;
            -webkit-transition: color .2s ease-in-out;
            -o-transition: color .2s ease-in-out;
            transition: color .2s ease-in-out;
            border: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            &::before {
                position: absolute;
                content: "\f0d7";
                font-family: 'FontAwesome';
                color: #333;
                font-size: 20px;
                width: 22px;
                height: 22px;
                text-align: center;
                line-height: 25px;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                transition: 0.4s ease-in-out;
                border-radius: 100%;
                z-index: 4;
                margin-top: 0px;
            }
            &[aria-expanded="true"]::before {
                background: #fff;
                color: $theme-color;
            }
        }
    }
}
.faq-style-wrap .panel-title a[aria-expanded="true"] {
    border-radius: 3px 3px 0 0;
    color: $white;
    background: $theme-color;
}
.panel.panel-default {
    margin-bottom: 15px;
}
.panel-body {
    padding: 15px;
    border: 1px solid #ddd;
}
