/*--
    05. Support Css
--------------------------------*/
.custom-col-service {
    padding-right: 8px;
    padding-left: 8px;
    flex: 0 0 20%;
    max-width: 20%;
    position: relative;
    @media #{$desktop-device}{
        flex: 0 0 33.333%;
        max-width: 33.333%;
    }
    @media #{$tablet-device}{
        flex: 0 0 33.333%;
        max-width: 33.333%;
    }
    @media #{$large-mobile}{
        flex: 0 0 50%;
        max-width: 50%;
    }
    @media #{$small-mobile}{
        flex: 0 0 100%;
        max-width: 100%;
    }
    
}
.single-support {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    & .support-content {
        margin-left: 20px;
    }
}