/*--
    12. Breadcrumb Style
------------------------------------------*/

.breadcrumb-area {
    background: #F9F9F9;
    padding: 17px 0;
    border: 1px solid #ebebeb;
}
.breadcrumb-list {
    & .breadcrumb-item {
        font-size: 14px;
        text-transform: capitalize;
        line-height: 1;
        display: inline-block;
        &.active {
            color: $theme-color;
        }
        & a {
            &:hover {
                color: $theme-color;
            }
        }
    } 
}

.breadcrumb-item + .breadcrumb-item {
    &::before {
        content: "/";
        display: inline-block;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }  
}
    

